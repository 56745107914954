/* 캘린더 패널 */
.calendarPanel {
  flex: 1;
  background-color: white;
  border: 3px solid var(--border-color);
  padding: 15px;
  box-shadow: 6px 6px 0 var(--shadow-color);
  display: flex;
  flex-direction: column;
  max-width: 350px;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
  height: 600px;
}

.calendarPanel h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--secondary-color);
  border-bottom: 4px solid var(--primary-color);
  padding-bottom: 10px;
  font-size: 16px;
}

/* 커스텀 캘린더 스타일 */
.customCalendar {
  width: 100%;
  border: 3px solid var(--border-color) !important;
  background-color: white !important;
  box-shadow: 4px 4px 0 var(--shadow-color) !important;
  font-family: inherit !important;
}

/* 일정 있는 날짜 스타일 */
.hasSchedule {
  background-color: var(--accent-color) !important;
  color: white !important;
  font-weight: bold;
  border: 2px solid var(--border-color) !important;
  box-shadow: 2px 2px 0 var(--shadow-color) !important;
}

.hasSchedule:hover {
  background-color: var(--primary-color) !important;
  transform: translate(-1px, -1px);
  box-shadow: 3px 3px 0 var(--shadow-color) !important;
}

/* react-calendar 기본 클래스 스타일 */
:global(.react-calendar__tile--active) {
  background-color: var(--primary-color) !important;
  border: 2px solid var(--border-color) !important;
  box-shadow: 2px 2px 0 var(--shadow-color) !important;
}

:global(.react-calendar__tile--now) {
  background-color: var(--warning-color) !important;
  color: white !important;
  border: 2px solid var(--border-color) !important;
  box-shadow: 2px 2px 0 var(--shadow-color) !important;
}

:global(.react-calendar__tile:hover) {
  background-color: var(--accent-color) !important;
  color: white !important;
  transform: translate(-1px, -1px);
  box-shadow: 3px 3px 0 var(--shadow-color) !important;
}

:global(.react-calendar__navigation button) {
  font-family: inherit !important;
  border: 2px solid var(--border-color) !important;
  background-color: var(--primary-color) !important;
  color: white !important;
  box-shadow: 2px 2px 0 var(--shadow-color) !important;
}

:global(.react-calendar__navigation button:hover) {
  background-color: var(--accent-color) !important;
  transform: translate(-1px, -1px);
  box-shadow: 3px 3px 0 var(--shadow-color) !important;
}

:global(.react-calendar__month-view__weekdays) {
  font-family: inherit !important;
  color: var(--secondary-color) !important;
  text-decoration: none !important;
  font-weight: normal !important;
}

:global(.react-calendar__month-view__days__day) {
  font-family: inherit !important;
  color: var(--secondary-color) !important;
}

@media (max-width: 768px) {
  .calendarPanel {
    max-width: none;
  }
} 