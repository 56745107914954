.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.agent {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  position: fixed;
  top: 20px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 70%; /* 1920의 70%로 설정 */
  max-width: 1344px; /* 1920의 70% */
  height: 70%; /* 1080의 70%로 설정 */
  max-height: 756px; /* 1080의 70% */
  position: relative;
  margin-top: 80px; /* 인공지능 에이전트 공간 확보 */
  overflow-y: auto;
}

.closeButton {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}