/* 기본 색상 변수 정의 */
:root {
  --primary-color: #ff4757;
  --secondary-color: #2f3542;
  --accent-color: #ffa502;
  --background-color: #f1f2f6;
  --border-color: #2f3542;
  --shadow-color: rgba(47, 53, 66, 0.2);
  --success-color: #2ed573;
  --warning-color: #ffa502;
  --error-color: #ff4757;
}

.managementModalContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
  image-rendering: pixelated;
}

.managementContent {
  display: flex;
  height: 100%;
  gap: 15px;
  overflow-x: auto;
  padding: 15px;
}

/* 로딩 컨테이너 스타일 */
.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid var(--background-color);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 스크롤바 스타일 */
.managementContent::-webkit-scrollbar {
  width: 12px;
}

.managementContent::-webkit-scrollbar-track {
  background: var(--background-color);
}

.managementContent::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 6px;
  border: 3px solid var(--background-color);
}

/* 직원 상세 섹션 */
.employeeDetailSection {
  flex: 1;
  height: 100%;
  overflow: hidden;
}

/* 노선택 상태 스타일 */
.noSelection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--secondary-color);
  font-size: 14px;
  text-align: center;
  padding: 20px;
  background-color: white;
  border: 2px solid var(--border-color);
  box-shadow: 3px 3px 0 var(--shadow-color);
  margin: 20px 0;
}

/* 입력 요소 픽셀 스타일 - 공통으로 유지 */
input, textarea, select {
  border: 3px solid var(--border-color);
  background-color: white;
  padding: 5px;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
  image-rendering: pixelated;
  box-shadow: 3px 3px 0 var(--shadow-color);
}

input:focus, textarea:focus, select:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 3px 3px 0 rgba(255, 165, 2, 0.5);
}

/* 체크박스 픽셀 스타일 */
input[type="checkbox"], input[type="radio"] {
  transform: scale(1.5);
  margin-right: 5px;
  accent-color: var(--accent-color);
}

/* 버튼 스타일 통일 */
.btnPrimary,
.btnEditEmployee,
.btnDeleteEmployee,
.btnSaveEmployee,
.btnCancelEdit {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: 3px solid var(--border-color);
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
  font-family: inherit;
  box-shadow: 4px 4px 0 var(--shadow-color);
  text-transform: uppercase;
}

.btnPrimary:hover,
.btnEditEmployee:hover,
.btnDeleteEmployee:hover,
.btnSaveEmployee:hover,
.btnCancelEdit:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.btnPrimary:active,
.btnEditEmployee:active,
.btnDeleteEmployee:active,
.btnSaveEmployee:active,
.btnCancelEdit:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

/* 근무지 설정 탭 스타일 */
.workplaceContent {
  padding: 15px;
  background-color: white;
  height: 100%;
  width: 100%;
  overflow-x: auto;
}

/* 근무지 스크롤바 스타일 */
.workplaceContent::-webkit-scrollbar {
  width: 12px;
}

.workplaceContent::-webkit-scrollbar-track {
  background: var(--background-color);
}

.workplaceContent::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 6px;
  border: 3px solid var(--background-color);
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .managementContent {
    flex-direction: column;
  }
}