.agentSuggestionsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
}

.suggestionsHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.suggestionsHeader h2 {
  margin: 0;
  color: var(--secondary-color);
}

.filterControls {
  display: flex;
  gap: 15px;
}

.filterGroup {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filterGroup label {
  font-size: 14px;
  color: var(--secondary-color);
}

.filterGroup select {
  padding: 8px 12px;
  border: 3px solid var(--border-color);
  background-color: white;
  font-family: inherit;
  font-size: 14px;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.filterGroup select:focus {
  outline: none;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.suggestionsContent {
  display: flex;
  gap: 20px;
  height: 100%;
}

.suggestionsList {
  flex: 1;
  overflow-y: auto;
}

.suggestionsTable {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border: 3px solid var(--border-color);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.suggestionsTable th {
  padding: 12px 15px;
  background-color: var(--primary-color);
  color: white;
  text-align: left;
  font-weight: normal;
  position: sticky;
  top: 0;
  z-index: 1;
  border: 2px solid var(--border-color);
}

.suggestionsTable td {
  padding: 12px 15px;
  border: 2px solid var(--border-color);
}

.suggestionsTable tr:nth-child(even) {
  background-color: var(--background-color);
}

.suggestionsTable tr:hover {
  background-color: var(--accent-color);
  color: white;
}

.selectedRow {
  background-color: var(--accent-color) !important;
  color: white;
}

.suggestionContent {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statusBadge {
  display: inline-block;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  border: 2px solid var(--border-color);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.adopted {
  background-color: var(--success-color);
  color: white;
}

.rejected {
  background-color: var(--error-color);
  color: white;
}

.pending {
  background-color: var(--warning-color);
  color: white;
}

.categoryBadge {
  display: inline-block;
  padding: 4px 8px;
  font-size: 12px;
  color: white;
  border: 2px solid var(--border-color);
  box-shadow: 2px 2px 0 var(--shadow-color);
  background-color: var(--accent-color);
}

.priorityBadge {
  display: inline-block;
  padding: 4px 8px;
  font-size: 12px;
  color: white;
  border: 2px solid var(--border-color);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.priorityHigh {
  background-color: var(--error-color);
}

.priorityMedium {
  background-color: var(--warning-color);
}

.priorityLow {
  background-color: var(--accent-color);
}

.btnView {
  padding: 5px 10px;
  background-color: var(--secondary-color);
  color: white;
  border: 2px solid var(--border-color);
  cursor: pointer;
  font-family: inherit;
  font-size: 12px;
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.btnView:hover {
  transform: translate(-1px, -1px);
  box-shadow: 3px 3px 0 var(--shadow-color);
}

.btnView:active {
  transform: translate(1px, 1px);
  box-shadow: 1px 1px 0 var(--shadow-color);
}

.noData {
  text-align: center;
  padding: 30px;
  color: var(--secondary-color);
  background-color: white;
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.suggestionDetailPanel {
  flex: 0 0 350px;
  background-color: white;
  border: 3px solid var(--border-color);
  padding: 15px;
  overflow-y: auto;
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.suggestionDetailPanel h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--secondary-color);
  border-bottom: 4px solid var(--primary-color);
  padding-bottom: 10px;
}

.suggestionDetail {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.detailGroup {
  display: flex;
  align-items: center;
}

.detailGroup.fullWidth {
  flex-direction: column;
  align-items: flex-start;
}

.detailLabel {
  width: 80px;
  font-weight: bold;
  color: var(--secondary-color);
}

.detailGroup.fullWidth .detailLabel {
  margin-bottom: 5px;
}

.detailValue {
  flex: 1;
  color: var(--secondary-color);
}

.detailGroup input[type="text"] {
  width: 100%;
  padding: 8px 10px;
  border: 3px solid var(--border-color);
  font-family: inherit;
  font-size: 14px;
  background-color: white;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.detailGroup input[type="text"]:focus {
  outline: none;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.statusControl {
  flex-direction: column;
  align-items: flex-start;
}

.radioControls {
  display: flex;
  gap: 15px;
  margin-top: 8px;
}

.radioControls label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: var(--secondary-color);
}

.btnGenerate {
  padding: 10px 20px;
  background-color: var(--success-color);
  color: white;
  border: 3px solid var(--border-color);
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  box-shadow: 4px 4px 0 var(--shadow-color);
  text-transform: uppercase;
}

.btnGenerate:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.btnGenerate:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.btnGenerate:disabled {
  background-color: var(--secondary-color);
  opacity: 0.5;
  cursor: not-allowed;
}

.overlayLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
}

/* 스크롤바 스타일 */
.suggestionsList::-webkit-scrollbar,
.suggestionDetailPanel::-webkit-scrollbar {
  width: 16px;
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

.suggestionsList::-webkit-scrollbar-thumb,
.suggestionDetailPanel::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 3px solid var(--border-color);
}

.suggestionsList::-webkit-scrollbar-track,
.suggestionDetailPanel::-webkit-scrollbar-track {
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

@media (max-width: 768px) {
  .suggestionsContent {
    flex-direction: column;
  }
  
  .suggestionDetailPanel {
    flex: none;
    width: 100%;
  }
  
  .filterControls {
    flex-direction: column;
    gap: 10px;
  }
  
  .filterGroup {
    width: 100%;
  }
  
  .filterGroup select {
    flex: 1;
  }
  
  .btnGenerate {
    width: 100%;
  }
}