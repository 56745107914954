/* 폼 스타일 */
.employeeForm {
  border: 4px solid var(--border-color);
  padding: 12px;
  background: white;
  box-shadow: 6px 6px 0 var(--shadow-color);
  height: 100%;
  overflow-y: auto;
}

.employeeForm h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
}

.formGroup {
  margin-bottom: 8px;
}

.formGroup label {
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
}

.formGroup input,
.formGroup textarea {
  padding: 5px;
  width: calc(100% - 12px);
  box-sizing: border-box;
}

/* 더 좁은 간격의 행 */
.formRow {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  width: 100%;
  flex-wrap: wrap;
}

.formRow .formGroup {
  flex: 1;
  min-width: 120px;
}

.formColumns {
  display: flex;
  gap: 12px;
}

.formColumn {
  flex: 1;
}

/* 인라인 라디오 버튼 */
.inlineRadioGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.inlineRadioGroup label {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 0;
  white-space: nowrap;
}

/* 컴팩트한 보험 섹션 */
.compactInsuranceSection {
  display: flex;
  flex-wrap: wrap;
  gap: 4px 8px;
  padding: 4px;
  border: 2px solid var(--border-color);
  background: var(--background-color);
  width: calc(100% - 10px);
  box-sizing: border-box;
}

.inlineCheckbox {
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  font-size: 13px;
  white-space: nowrap;
  flex-shrink: 0;
}

.inlineCheckbox input[type="checkbox"] {
  margin-right: 4px;
  width: auto;
}

/* 버튼 스타일 */
.addWorkplaceBtn, 
.showCustomerBtn, 
.deleteWorkplaceBtn, 
.saveBtn, 
.deleteBtn, 
.cancelBtn {
  image-rendering: pixelated;
  border: 3px solid #000;
  box-shadow: 4px 4px 0px #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  color: #000;
  position: relative;
  transition: all 0.1s;
  cursor: pointer;
  outline: none;
  padding: 6px 12px;
  font-size: 13px;
  text-align: center;
}

.addWorkplaceBtn:active, 
.showCustomerBtn:active, 
.deleteWorkplaceBtn:active, 
.saveBtn:active, 
.deleteBtn:active, 
.cancelBtn:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0px #000;
}

.addWorkplaceBtn {
  background-color: #42c5f5;
  color: #000;
  padding: 4px 8px;
  font-size: 12px;
}

.showCustomerBtn {
  background-color: #f59342;
  color: #000;
  padding: 4px 8px;
  font-size: 12px;
}

.deleteWorkplaceBtn {
  background-color: #f54242;
  color: #fff;
  font-size: 11px;
  padding: 2px 5px;
  box-shadow: 2px 2px 0px #000;
}

.deleteWorkplaceBtn:active {
  transform: translate(1px, 1px);
  box-shadow: 1px 1px 0px #000;
}

.saveBtn {
  background-color: #4287f5;
  color: #fff;
}

.deleteBtn {
  background-color: #f54242;
  color: #fff;
}

.cancelBtn {
  background-color: #7a7a7a;
  color: #fff;
}

/* 더 컴팩트한 워크플레이스 섹션 */
.workplaceSection {
  margin-bottom: 12px;
}

.workplaceAdd {
  display: flex;
  gap: 5px;
  margin-bottom: 6px;
}

.workplaceAdd input {
  flex: 1;
  width: calc(100% - 10px);
  box-sizing: border-box;
}

.workplaceList {
  max-height: 100px;
  overflow-y: auto;
  margin-bottom: 8px;
}

.workplaceItem {
  border: 3px solid var(--border-color);
  background: white;
  transition: all 0.15s;
  position: relative;
  image-rendering: pixelated;
  width: calc(100% - 8px);
  margin-right: 4px;
  box-sizing: border-box;
  padding: 5px 8px;
  margin-bottom: 5px;
  font-size: 13px;
}

.workplaceItem:hover {
  transform: translate(-2px, -2px);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.workplaceItem.selected {
  background-color: var(--accent-color);
  color: white;
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
  font-weight: bold;
  margin-left: 2px;
  margin-top: 2px;
}

/* 고객 목록 */
.customerListSection {
  border: 2px solid var(--border-color);
  background: var(--background-color);
  padding: 6px;
  margin-bottom: 8px;
  max-height: 120px;
  overflow-y: auto;
}

.customerList {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.customerItem {
  border: 3px solid var(--border-color);
  background: white;
  transition: all 0.15s;
  position: relative;
  image-rendering: pixelated;
  width: calc(100% - 8px);
  margin-right: 4px;
  box-sizing: border-box;
  padding: 4px;
  cursor: pointer;
  font-size: 12px;
}

.noCustomers {
  text-align: center;
  padding: 10px 0;
  color: #888;
  font-style: italic;
}

/* 근무지 상세 정보 */
.workplaceDetails {
  padding: 6px;
  border: 2px solid var(--border-color);
  background: var(--background-color);
  margin-top: 5px;
}

.superCompactForm .formRow {
  display: flex;
  gap: 8px;
  margin-bottom: 5px;
}

.superCompactForm .formGroup {
  flex: 1;
  margin-bottom: 5px;
}

.superCompactForm label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  margin-bottom: 3px;
}

.superCompactForm input[type="checkbox"] {
  margin: 0;
  height: auto;
  width: auto;
}

.superCompactForm input {
  padding: 3px 5px;
  height: 26px;
  width: calc(100% - 2px);
  box-sizing: border-box;
}

.inlineInputGroup {
  display: flex;
  align-items: center;
  gap: 6px;
}

.defaultLabel {
  margin-left: 5px;
  background-color: var(--accent-color);
  color: white;
  padding: 2px 4px;
  font-size: 10px;
  border: 2px solid black;
  text-transform: uppercase;
}

/* 폼 액션 버튼 */
.formActions {
  display: flex;
  gap: 10px;
  margin-top: 12px;
  padding-top: 10px;
  border-top: 3px solid #2f3542;
}

.formActions button {
  padding: 8px 16px;
  flex: 1;
  max-width: 150px;
  font-weight: bold;
} 