/* 기본 컨테이너와 헤더 */
.qrStatusContainer {
  width: 100%;
  height: 100%;
  padding: 20px;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
  background-color: var(--background-color, #f5f5f5);
}

h2 {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 4px solid var(--primary-color, #ff3333);
  color: var(--secondary-color, #333);
  font-size: 24px;
}

/* 월 선택기 */
.monthSelector {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: white;
  border: 3px solid var(--border-color, #333);
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3);
}

.monthSelector button {
  padding: 8px 16px;
  background-color: var(--primary-color, #ff3333);
  color: white;
  border: 3px solid var(--border-color, #333);
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.monthSelector span {
  font-size: 18px;
  font-weight: bold;
}

/* 출결 테이블 */
.attendanceTable {
  width: 100%;
  overflow-x: auto;
  background-color: white;
  border: 3px solid var(--border-color, #333);
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3);
}

.tableHeader,
.tableRow {
  display: grid;
  grid-template-columns: 120px repeat(31, 25px);
}

.tableHeader {
  background-color: var(--primary-color, #ff3333);
  position: sticky;
  top: 0;
  z-index: 1;
}

.employeeColumn {
  padding: 4px;
  font-size: 12px;
  border-right: 2px solid var(--border-color, #333);
  background-color: var(--secondary-color, #666);
  color: white;
  position: sticky;
  left: 0;
  text-align: center;
}

.dateColumn {
  padding: 4px 0;
  font-size: 11px;
  border-right: 1px solid #eee;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* 출결 상태 */
.status출근, .status미출근, .status연차, .status반차, .status기타 {
  font-weight: bold;
}
.status출근 { background: var(--success-color, #4CAF50); color: white; }
.status미출근 { background: var(--error-light, #ffe6e6); color: #dc3545; }
.status연차 { background: var(--info-light, #e3f2fd); color: #1976d2; }
.status반차 { background: var(--warning-light, #fff3e0); color: #f57c00; }
.status기타 { background: var(--purple-light, #f3e5f5); color: #7b1fa2; }

/* 모달 */
.modalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContentAttendance {
  background: white;
  border: 3px solid var(--border-color, #333);
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3);
  padding: 20px;
  width: 90%;
  max-width: 400px;
  max-height: 80vh;
  overflow-y: auto;
}

/* 모달 폼 */
.attendanceTypeGroup,
.workplaceSelectGroup,
.timeInputGroup,
.leaveNoteGroup {
  margin-bottom: 15px;
}

.attendanceTypeGroup label,
.workplaceSelectGroup label,
.timeInputGroup label,
.leaveNoteGroup label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
}

.attendanceTypeGroup select,
.workplaceSelectGroup select,
.timeInputGroup input,
.leaveNoteGroup input {
  width: 100%;
  padding: 8px 12px;
  border: 3px solid var(--border-color, #333);
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
  font-size: 14px;
}

/* 모달 버튼 */
.modalActions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.modalActions button {
  padding: 8px 16px;
  border: 3px solid var(--border-color, #333);
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
  font-size: 14px;
  cursor: pointer;
  color: white;
}

.saveButton { background: #4CAF50; }
.deleteButton { background: #ff9800; }
.cancelButton { background: #666; }
.absentButton { background: #f44336; }

/* 근무 기록 선택 탭 */
.recordButton {
  color: #333; /* 탭 글자색 수정 */
  background: white;
}

.recordButton.selected {
  background: var(--primary-color, #ff3333);
  color: white;
}

/* 반응형 */
@media (max-width: 768px) {
  .monthSelector {
    flex-direction: column;
    gap: 10px;
  }
  .tableHeader,
  .tableRow {
    grid-template-columns: 100px repeat(31, 22px);
  }
  .dateColumn {
    font-size: 10px;
    padding: 2px 0;
    height: 22px;
  }
}