/* 기본 색상 변수 정의 */
:root {
  --primary-color: #ff4757;
  --secondary-color: #2f3542;
  --accent-color: #ffa502;
  --background-color: #f1f2f6;
  --border-color: #2f3542;
  --shadow-color: rgba(47, 53, 66, 0.2);
}

/* 설정 화면 */
.settingsContainer {
  padding: 20px;
  padding-bottom: 50px;
  border: 4px solid var(--border-color);
  background: white;
  box-shadow: 6px 6px 0 var(--shadow-color);
  overflow-y: auto;
  max-height: 90vh;
  height: 600px;
}

.settingsGroup {
  margin-bottom: 20px;
  border: 4px solid var(--border-color);
  padding: 15px;
  background: var(--background-color);
}

.settingsGroup h4 {
  margin: 0 0 15px 0;
  padding-bottom: 10px;
  border-bottom: 4px solid var(--accent-color);
  color: var(--secondary-color);
}

.settingsRow {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.settingsRow input[type="number"],
.settingsRow input[type="time"],
.settingsRow select {
  padding: 8px;
  border: 4px solid var(--border-color);
  background: white;
  font-family: inherit;
  box-shadow: 4px 4px 0 var(--shadow-color);
  margin-left: 10px;
  transition: all 0.2s;
}

.settingsRow input:focus,
.settingsRow select:focus {
  outline: none;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.checkboxRow {
  margin-bottom: 10px;
}

.checkboxRow label {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.checkboxRow input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 4px solid var(--border-color);
  cursor: pointer;
}

.saveSettingsButton {
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: white;
  border: 4px solid var(--border-color);
  font-family: inherit;
  cursor: pointer;
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: all 0.2s;
}

.saveSettingsButton:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.settingsForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.settingsActions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .settingsRow {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .settingsRow input,
  .settingsRow select {
    width: 100%;
    margin-left: 0;
  }
} 