/* 게임형 레트로 픽셀 테마 - 독립 색상 */
.rightsideMenu {
  --rm-primary-color: #000000;
  --rm-secondary-color: #d1d8e0;
  --rm-accent-color: #ff4757;
  --rm-background-color: #ffffff;
  --rm-border-color: #2f3542;
  --rm-shadow-color: rgba(0, 0, 0, 0.25);
  --rm-text-color: #000000;
  --rm-menu-item-bg: #ffffff;
  
  display: flex;
  flex-direction: column;
  background-color: var(--rm-background-color);
  background-image: none;
  padding: 20px;
  width: 100%;
  box-shadow: -5px 0 15px var(--rm-shadow-color);
  align-items: center;
  flex: 1;
  overflow-y: auto;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  image-rendering: pixelated;
  color: var(--rm-text-color);
}

.rightsideMenuContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  padding: 20px 0;
  position: relative;
}

/* 기존 ::before 가상 요소 제거하고 실제 로고 컨테이너로 대체 */
.logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  padding: 15px 0;
  background-color: #ffffff;
  width: 85%;
  border: 3px solid #000000;
  border-radius: 8px;
  box-shadow: 0 6px 0 #000000, 0 10px 20px rgba(0, 0, 0, 0.2);
  animation: floating 3s ease-in-out infinite;
}

.logo {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  filter: none;
}

.logoText {
  font-size: 18px;
  font-weight: bold;
  color: rgb(45, 137, 239);
  margin: 0;
  letter-spacing: 1px;
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.rightsideMenuItem {
  width: 85%;
  margin: 8px auto;
  background-color: var(--rm-menu-item-bg);
  padding: 16px 20px;
  border: 2px solid #000000;
  cursor: pointer;
  color: #000000;
  font-size: 16px;
  font-family: 'Noto Sans KR', sans-serif;
  text-align: left;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  border-radius: 0;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
  font-weight: 600;
  letter-spacing: 0.5px;
}

/* 메뉴 아이콘 절제된 스타일 */
.rightsideMenuItem::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: rgb(45, 137, 239);
  transition: all 0.2s ease;
}

.rightsideMenuItem:hover {
  background-color: #ffeef0;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3);
  font-weight: bold;
}

.rightsideMenuItem:hover::before {
  width: 6px;
}

.rightsideMenuItem:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
}

/* 메뉴 아이템 하단 구분선 */
.rightsideMenuContainer::after {
  content: "";
  display: block;
  width: 85%;
  height: 3px;
  background-color: #000000;
  margin-top: auto;
  margin-bottom: 10px;
  background-image: linear-gradient(to right, 
    #000000 70%, 
    transparent 70%
  );
  background-size: 8px 3px;
}

.rightsideLogoutButton {
  background-color: #ffffff;
  color: #ff0000;
  padding: 12px 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 2px solid #ff0000;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  transition: all 0.2s ease;
  align-self: center;
  width: 85%;
  font-family: 'Noto Sans KR', sans-serif;
  position: relative;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
  letter-spacing: 0.5px;
}

.rightsideLogoutButton:hover {
  background-color: #ff0000;
  color: #ffffff;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3);
}

.rightsideLogoutButton:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
}

/* 스크롤바 스타일링 */
.rightsideMenu::-webkit-scrollbar {
  width: 10px;
  background-color: #e6e6e6;
  border-left: 2px solid #000000;
}

.rightsideMenu::-webkit-scrollbar-thumb {
  background-color: #454545;
  border: 2px solid #e6e6e6;
}

/* 스크롤바 버튼 */
.rightsideMenu::-webkit-scrollbar-button {
  background-color: #e6e6e6;
  height: 10px;
  border: 1px solid #000000;
}

.rightsideMenu::-webkit-scrollbar-button:vertical:start {
  background-image: linear-gradient(to top, #000000 40%, transparent 40%);
  background-size: 6px 6px;
  background-repeat: no-repeat;
  background-position: center;
}

.rightsideMenu::-webkit-scrollbar-button:vertical:end {
  background-image: linear-gradient(to bottom, #000000 40%, transparent 40%);
  background-size: 6px 6px;
  background-repeat: no-repeat;
  background-position: center;
}
