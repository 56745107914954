.privacyContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.privacyContent {
  line-height: 1.6;
}

.privacyContent h1 {
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
}

.privacyContent h2 {
  color: #444;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5rem;
}

.privacyContent section {
  margin-bottom: 2rem;
}

.privacyContent ul, .privacyContent ol {
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.privacyContent p {
  margin-bottom: 1rem;
}

.footerLinks {
  margin-top: 2rem;
  text-align: center;
}

.backLink {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #4a6cf7;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.backLink:hover {
  background-color: #3a5ce5;
}

@media (max-width: 768px) {
  .privacyContainer {
    padding: 1rem;
    margin: 0 1rem;
  }
}

.privacyText {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-family: inherit;
  font-size: 1rem;
} 