.notice {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    margin-top: 20px;
    width: 100%;
    height: auto;
    min-height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}