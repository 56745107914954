/* 기본 색상 변수 정의 */
:root {
  --primary-color: #ff4757;
  --secondary-color: #2f3542;
  --accent-color: #ffa502;
  --background-color: #f1f2f6;
  --border-color: #2f3542;
  --shadow-color: rgba(47, 53, 66, 0.2);
  --success-color: #2ed573;
  --warning-color: #ffa502;
  --error-color: #ff4757;
}

.payrollResultContainer {
  width: 100%;
  overflow-y: auto;
  max-height: 80vh;
  height: 300px;
  max-width: 1200px;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  image-rendering: pixelated;
}

.debugInfo {
  padding: 8px;
  border: 2px solid var(--border-color);
  background: var(--background-color);
  margin-bottom: 15px;
  font-size: 12px;
  color: var(--secondary-color);
}

.employeePayroll {
  margin-bottom: 15px;
  border: 4px solid var(--border-color);
  background: white;
  box-shadow: 6px 6px 0 var(--shadow-color);
  transition: all 0.2s;
}

.employeePayroll:hover {
  transform: translate(-2px, -2px);
  box-shadow: 8px 8px 0 var(--shadow-color);
}

.completedEmployee {
  border-left: 8px solid var(--success-color);
}

.payrollHeader {
  cursor: pointer;
  padding: 15px;
  background: var(--background-color);
  border-bottom: 4px solid var(--border-color);
}

.employeeBasicInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.employeeName {
  font-size: 16px;
  font-weight: bold;
  color: var(--secondary-color);
}

.workTime {
  font-size: 14px;
  color: var(--secondary-color);
}

.payrollSummaryRow {
  display: flex;
  justify-content: space-between;
  background: var(--background-color);
  padding: 10px;
  border: 3px solid var(--border-color);
  margin: 10px;
}

.summaryItem {
  text-align: center;
  flex: 1;
  padding: 5px;
  border-right: 2px solid var(--border-color);
}

.summaryItem:last-child {
  border-right: none;
}

.summaryLabel {
  font-size: 12px;
  color: var(--secondary-color);
  margin-bottom: 5px;
}

.summaryValue {
  font-weight: bold;
  color: var(--primary-color);
  font-size: 14px;
}

.highlightSummary .summaryValue {
  color: var(--accent-color);
}

.completedBadge {
  display: inline-block;
  background: var(--success-color);
  color: white;
  font-size: 12px;
  padding: 4px 8px;
  margin-left: 8px;
  border: 2px solid var(--border-color);
}

/* 버튼 스타일 */
.actionButton {
  padding: 8px 16px;
  border: 3px solid var(--border-color);
  font-family: inherit;
  cursor: pointer;
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: all 0.2s;
  margin: 5px;
}

.saveButton {
  padding: 8px 16px;
  border: 3px solid var(--border-color);
  font-family: inherit;
  cursor: pointer;
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: all 0.2s;
  margin: 5px;
  background: var(--accent-color);
  color: white;
}

.completeButton {
  padding: 8px 16px;
  border: 3px solid var(--border-color);
  font-family: inherit;
  cursor: pointer;
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: all 0.2s;
  margin: 5px;
  background: var(--success-color);
  color: white;
}

.saveButton:hover,
.completeButton:hover,
.actionButton:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.saveButton:active,
.completeButton:active,
.actionButton:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

/* 입력 필드 스타일 */
.inputField {
  padding: 8px;
  border: 3px solid var(--border-color);
  background: white;
  font-family: inherit;
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: all 0.2s;
  width: 100%;
  margin: 5px 0;
}

.inputField:focus {
  outline: none;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

/* 상태 표시 스타일 */
.savingStatus {
  padding: 4px 8px;
  border: 2px solid var(--border-color);
  font-size: 12px;
  margin-left: 8px;
}

.statusSaving {
  padding: 4px 8px;
  border: 2px solid var(--border-color);
  font-size: 12px;
  margin-left: 8px;
  background: var(--warning-color);
  color: white;
}

.statusSaved {
  padding: 4px 8px;
  border: 2px solid var(--border-color);
  font-size: 12px;
  margin-left: 8px;
  background: var(--success-color);
  color: white;
}

.statusError {
  padding: 4px 8px;
  border: 2px solid var(--border-color);
  font-size: 12px;
  margin-left: 8px;
  background: var(--error-color);
  color: white;
}

/* 상세 정보 섹션 */
.detailSection {
  padding: 15px;
  border-top: 4px solid var(--border-color);
  background: var(--background-color);
}

.detailRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px;
  border: 3px solid var(--border-color);
  background: white;
}

.detailLabel {
  flex: 1;
  font-size: 14px;
  color: var(--secondary-color);
}

.detailValue {
  flex: 2;
  text-align: right;
  font-weight: bold;
  color: var(--primary-color);
}

/* 스크롤바 스타일 */
.employeePayroll::-webkit-scrollbar {
  width: 16px;
  background: var(--background-color);
  border: 4px solid var(--border-color);
}

.employeePayroll::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 4px solid var(--border-color);
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .payrollSummaryRow {
    flex-direction: column;
  }

  .summaryItem {
    border-right: none;
    border-bottom: 2px solid var(--border-color);
    padding: 10px 0;
  }

  .summaryItem:last-child {
    border-bottom: none;
  }

  .detailRow {
    flex-direction: column;
    text-align: center;
  }

  .detailValue {
    text-align: center;
    margin-top: 5px;
  }
}