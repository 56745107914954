:root {
  --primary-color: #ff4757;
  --secondary-color: #2f3542;
  --accent-color: #ffa502;
  --background-color: #f1f2f6;
  --border-color: #2f3542;
  --shadow-color: rgba(47, 53, 66, 0.2);
  --success-color: #2ed573;
  --warning-color: #ffa502;
  --error-color: #ff4757;
}

.payrollHeader {
  cursor: pointer;
  padding: 15px;
  background-color: var(--background-color);
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
  transition: all 0.2s;
  flex-shrink: 0;
}

.payrollHeader:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.payrollHeader:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.employeeBasicInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 8px;
  background: white;
  border: 2px solid var(--border-color);
}

.employeeName {
  font-weight: bold;
  font-size: 14px;
  color: var(--secondary-color);
  display: flex;
  align-items: center;
  gap: 8px;
}

.workTime {
  color: var(--secondary-color);
  font-size: 12px;
  padding: 4px 8px;
  background: var(--background-color);
  border: 2px solid var(--border-color);
}

.payrollSummaryRow {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 10px;
  border: 3px solid var(--border-color);
  gap: 10px;
}

.summaryItem {
  text-align: center;
  flex: 1;
  padding: 8px;
  background: var(--background-color);
  border: 2px solid var(--border-color);
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.summaryLabel {
  font-size: 12px;
  color: var(--secondary-color);
  text-transform: uppercase;
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 4px;
}

.summaryValue {
  font-weight: bold;
  color: var(--secondary-color);
  font-size: 13px;
}

.highlightSummary {
  background: var(--accent-color);
}

.highlightSummary .summaryLabel {
  color: white;
  border-bottom-color: white;
}

.highlightSummary .summaryValue {
  color: white;
  font-size: 14px;
}

.completedBadge {
  display: inline-block;
  background-color: var(--success-color);
  color: white;
  font-size: 10px;
  padding: 4px 8px;
  border: 2px solid var(--border-color);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .payrollSummaryRow {
    flex-wrap: wrap;
  }

  .summaryItem {
    flex: 1 1 45%;
  }
}

@media (max-width: 576px) {
  .employeeBasicInfo {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .summaryItem {
    flex: 1 1 100%;
  }
} 