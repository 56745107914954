/* 고객 검색 관련 스타일 */
.customerSearchGroup {
  position: relative;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
}

.customerSearchInput {
  display: flex;
  gap: 10px;
}

.customerSearchInput input {
  flex: 1;
  padding: 8px 10px;
  border: 3px solid var(--border-color);
  background-color: white;
  font-family: inherit;
  font-size: 14px;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.customerSearchInput input:focus {
  outline: none;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.btnSearchCustomer {
  background-color: var(--primary-color);
  color: white;
  border: 3px solid var(--border-color);
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  font-family: inherit;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.btnSearchCustomer:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.btnSearchCustomer:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.customerSearchDropdown {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background-color: white;
  border: 3px solid var(--border-color);
  box-shadow: 6px 6px 0 var(--shadow-color);
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;
}

.customerSearchHeader {
  padding: 12px;
  border-bottom: 3px solid var(--border-color);
  background-color: var(--background-color);
}

.customerSearchHeader input {
  width: 100%;
  padding: 8px 10px;
  border: 3px solid var(--border-color);
  font-family: inherit;
  font-size: 14px;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.customerSearchHeader input:focus {
  outline: none;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.customerSearchResults {
  max-height: 250px;
  overflow-y: auto;
}

.customerItem {
  padding: 12px;
  border-bottom: 3px solid var(--border-color);
  cursor: pointer;
  transition: all 0.2s;
  background-color: white;
}

.customerItem:hover {
  background-color: var(--accent-color);
  color: white;
  transform: translate(-2px, -2px);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.customerItem:last-child {
  border-bottom: none;
}

.customerName {
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--secondary-color);
}

.customerItem:hover .customerName {
  color: white;
}

.customerContact {
  font-size: 14px;
  color: var(--secondary-color);
}

.customerItem:hover .customerContact {
  color: white;
}

.noCustomers {
  padding: 20px;
  text-align: center;
  color: var(--secondary-color);
  background-color: var(--background-color);
  border-top: 3px solid var(--border-color);
}

/* 스크롤바 스타일 */
.customerSearchDropdown::-webkit-scrollbar,
.customerSearchResults::-webkit-scrollbar {
  width: 16px;
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

.customerSearchDropdown::-webkit-scrollbar-thumb,
.customerSearchResults::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 3px solid var(--border-color);
}

.customerSearchDropdown::-webkit-scrollbar-track,
.customerSearchResults::-webkit-scrollbar-track {
  background: var(--background-color);
  border: 3px solid var(--border-color);
} 