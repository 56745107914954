.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.appBackground {
  background: linear-gradient(135deg, #3a3a3a 0%, #414141 100%);
  position: relative;
  overflow: hidden;
}

@keyframes starFall {
  0% {
    transform: translate(-50vw, -100vh); /* 1시 방향에서 시작 */
    opacity: 1;
  }
  100% {
    transform: translate(50vw, 100vh); /* 7시 방향으로 끝 */
    opacity: 0;
  }
}

.star {
  position: absolute;
  top: -10px;
  width: 5px;
  height: 5px;
  background: rgb(255, 230, 0);
  opacity: 0.8;
  animation: starFall 5s linear infinite;
  box-shadow: 0 0 10px rgba(255, 230, 0, 0.8);
  border: 1px solid rgb(100, 100, 100);
}
.star:nth-child(2) {
  left: 10%;
  animation-duration: 6s;
  animation-delay: 1s;
}

.star:nth-child(3) {
  left: 20%;
  animation-duration: 4s;
  animation-delay: 2s;
}

.star:nth-child(4) {
  left: 30%;
  animation-duration: 7s;
  animation-delay: 3s;
}

.star:nth-child(5) {
  left: 40%;
  animation-duration: 5s;
  animation-delay: 4s;
}

.star:nth-child(6) {
  left: 50%;
  animation-duration: 8s;
  animation-delay: 5s;
}

.star:nth-child(7) {
  left: 60%;
  animation-duration: 3s;
  animation-delay: 6s;
}

.star:nth-child(8) {
  left: 70%;
  animation-duration: 9s;
  animation-delay: 7s;
}

.star:nth-child(9) {
  left: 80%;
  animation-duration: 10s;
  animation-delay: 8s;
}

.star:nth-child(10) {
  left: 90%;
  animation-duration: 11s;
  animation-delay: 9s;
}

*, *::before, *::after {
  box-sizing: border-box;
}

/* body와 html의 오버플로우 숨기기 */
body, html {
  margin: 0;
  padding: 0;
}

/* 푸터 기본 스타일 및 반응형 설정 */
.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.appBackground {
  flex: 1;
}

/* 푸터 반응형 스타일 */
@media (max-width: 768px) {
  footer {
    padding: 10px;
    font-size: 0.9rem;
  }
  
  footer .footer-content {
    flex-direction: column;
    gap: 10px;
  }
  
  footer .footer-links {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  footer {
    padding: 8px;
    font-size: 0.8rem;
  }
  
  footer .copyright {
    text-align: center;
  }
}
