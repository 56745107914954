.chatBox {
  background-color: #2c1e0e;
  padding: 15px;
  border-radius: 0;
  width: 280px;
  height: 365px;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 1%;
  bottom: 1%;
  cursor: move;
  box-shadow: 4px 4px 0px #000000, 8px 8px 0px #4a3520;
  z-index: 9999;
  border: 3px solid #4a3520;
  image-rendering: pixelated;
}

.messagesContainer {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  overflow-anchor: none;
  background-color: #f0e6d2;
  border: 2px solid #4a3520;
  image-rendering: pixelated;
  background-image: 
    repeating-linear-gradient(180deg, transparent, transparent 15px, rgba(0,0,0,0.05) 15px, rgba(0,0,0,0.05) 16px);
}

.messagesContainer::-webkit-scrollbar {
  width: 6px;
}

.messagesContainer::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.messageRow {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  animation: fadeIn 0.3s ease-in-out;
  transform-origin: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.userMessageRow {
  justify-content: flex-end;
}

.aiMessageRow {
  justify-content: flex-start;
}

.userMessage, .aiMessage {
  max-width: 70%;
  padding: 10px;
  border-radius: 0;
  word-break: break-word;
  animation: popIn 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  image-rendering: pixelated;
  font-family: 'Consolas', monospace;
  font-size: 12px;
  line-height: 1.5;
  border: 2px solid #4a3520;
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.userMessage {
  background-color: #c19a6b;
  color: #2c1e0e;
  margin-left: auto;
}

.aiMessage {
  background-color: #e0c9a6;
  color: #2c1e0e;
  margin-right: auto;
}

.balloonImage {
  background-image: url('../../assets/images/inquiry_balloon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 25px;
  min-width: 200px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
  position: relative;
}

.balloonImage:hover {
  transform: scale(1.02);
}

.balloonImage p {
  margin: 0;
  padding: 0;
  word-break: break-word;
  text-align: center;
}

.contentImage {
  background-image: url('../../assets/images/inquiry_agent_answer.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 25px;
  min-width: 200px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  transition: transform 0.2s ease;
  position: relative;
}

.contentImage:hover {
  transform: scale(1.02);
}

.contentImage p {
  margin: 0;
  padding: 0;
  word-break: break-word;
  text-align: center;
}

.inputContainer {
  display: flex;
  align-items: center;
  background-color: #4a3520;
  padding: 10px;
  border-radius: 0;
  border: 2px solid #4a3520;
}

.textarea {
  flex: 1;
  height: 60px;
  border-radius: 0;
  border: 2px solid #4a3520;
  padding: 12px;
  font-size: 12px;
  resize: none;
  background-color: #f0e6d2;
  color: #2c1e0e;
  font-family: 'Consolas', monospace;
  box-shadow: inset 2px 2px 4px rgba(0,0,0,0.2);
}

.textarea:focus {
  outline: none;
  border-color: #c19a6b;
}

.sendButton {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #c19a6b;
  color: #2c1e0e;
  border: 2px solid #4a3520;
  border-radius: 0;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  font-family: 'Consolas', monospace;
  font-size: 12px;
  box-shadow: 2px 2px 0px #000000;
}

.sendButton:hover {
  background-color: #e0c9a6;
}

.sendButton:active {
  transform: translateY(0);
}

.warningRow {
  justify-content: center;
  margin: 10px 0;
}

.warningMessage {
  background-color: #ffecb3;
  color: #e65100;
  padding: 8px 15px;
  border-radius: 10px;
  text-align: center;
  animation: pulse 1s infinite alternate;
  font-weight: bold;
}

@keyframes pulse {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}

.fadeOutBox {
  opacity: 0;
  transition: opacity 1s ease;
}

.helpButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  margin-bottom: 8px;
  border-bottom: 2px dotted #4a3520;
}

.helpButton {
  background-color: #c19a6b;
  color: #2c1e0e;
  border: 2px solid #4a3520;
  border-radius: 0;
  padding: 6px 12px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: 'Consolas', monospace;
  box-shadow: 2px 2px 0px #000000;
}

.helpButton:hover {
  background-color: #e0c9a6;
}

.helpButton:disabled {
  background-color: #bdbdbd;
  cursor: not-allowed;
}
