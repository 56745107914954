/* 기본 색상 변수 정의 */
:root {
  --primary-color: #ff4757;
  --secondary-color: #2f3542;
  --accent-color: #ffa502;
  --background-color: #f1f2f6;
  --border-color: #2f3542;
  --shadow-color: rgba(47, 53, 66, 0.2);
  --success-color: #2ed573;
  --warning-color: #ffa502;
  --error-color: #ff4757;
}

.salaryModalContent {
  width: 100%;
  height: 650px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
  image-rendering: pixelated;
  box-sizing: border-box;
}

/* 급여 컨텐츠 영역 */
.salaryContent {
  overflow-x: auto;
  height: 100%;
  padding: 15px;
}

/* 로딩 컨테이너 스타일 */
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 18px;
  color: var(--secondary-color);
  background-color: rgba(255, 255, 255, 0.7);
}

/* 스크롤바 스타일 */
.salaryContent::-webkit-scrollbar {
  width: 16px;
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

.salaryContent::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 3px solid var(--border-color);
}

.salaryContent::-webkit-scrollbar-track {
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

/* 계산 화면 */
.calculationScreen {
  text-align: center;
  padding: 15px;
  border: 4px solid var(--border-color);
  background: white;
  box-shadow: 6px 6px 0 var(--shadow-color);
  margin: 15px;
}

.calculationScreen h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.yearMonthSelector {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: relative;
}

/* 연도별 타임라인 */
.yearTimeline {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px;
  overflow-x: auto;
  padding: 2px 0 5px 0;
  scrollbar-width: none;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
}

.yearTimeline::-webkit-scrollbar {
  display: none;
}

.yearTab {
  padding: 7px 12px;
  background-color: white;
  border: 2px solid var(--border-color);
  cursor: pointer;
  position: relative;
  text-align: center;
  min-width: 80px;
  font-weight: bold;
  box-shadow: 2px 2px 0 var(--shadow-color);
  transition: all 0.3s ease;
  height: auto;
  margin: 2px;
}

.yearTab.selected {
  background-color: var(--primary-color);
  color: white;
  transform: translateY(2px);
  box-shadow: 1px 1px 0 var(--shadow-color);
}

.yearTab.selected::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--primary-color);
}

.yearTab:hover:not(.selected) {
  background-color: var(--accent-color);
  color: white;
  transform: translateY(-3px);
  box-shadow: 3px 6px 0 var(--shadow-color);
}

/* 월 선택 그리드 */
.monthGrid {
  display: flex;
  flex-wrap: nowrap;
  gap: 3px;
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  padding: 2px 0 5px 0;
  justify-content: center;
  scrollbar-width: none;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.monthGrid::-webkit-scrollbar {
  display: none;
}

.monthTile {
  padding: 5px 2px;
  text-align: center;
  border: 2px solid var(--border-color);
  background-color: white;
  cursor: pointer;
  position: relative;
  box-shadow: 2px 2px 0 var(--shadow-color);
  transition: all 0.25s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
}

.monthTile.selected {
  background-color: var(--primary-color);
  color: white;
  transform: translate(2px, 2px);
  box-shadow: 1px 1px 0 var(--shadow-color);
}

.monthTile:hover:not(.selected) {
  background-color: var(--accent-color);
  color: white;
  transform: translate(-2px, -2px);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.monthTile .monthNumber {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: -3px;
}

.monthTile .monthLabel {
  font-size: 11px;
  margin-top: 2px;
}

.calculateButton {
  padding: 12px 25px;
  background-color: var(--primary-color);
  color: white;
  border: 3px solid var(--border-color);
  cursor: pointer;
  font-family: inherit;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: all 0.2s;
}

.calculateButton:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.calculateButton:active {
  transform: translate(2px, 2px);
  box-shadow: 1px 1px 0 var(--shadow-color);
}

.calculateButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.resultContainer {
  padding: 15px;
  overflow-y: auto;
}

.resultHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--primary-color);
}

.resultHeader h2 {
  margin: 0;
  font-size: 20px;
}

.resultActions {
  display: flex;
  gap: 10px;
}

.resetButton {
  padding: 8px 15px;
  background-color: var(--secondary-color);
  color: white;
  border: 3px solid var(--border-color);
  cursor: pointer;
  font-family: inherit;
  box-shadow: 3px 3px 0 var(--shadow-color);
  transition: all 0.2s;
}

.resetButton:hover {
  transform: translate(-2px, -2px);
  box-shadow: 5px 5px 0 var(--shadow-color);
}

.loadingScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.loadingSpinner {
  border: 5px solid var(--background-color);
  border-top: 5px solid var(--primary-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px 0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 결과 연월 선택기 스타일 */
.resultYearMonthSelector {
  margin-bottom: 15px;
  padding: 10px;
  background-color: var(--background-color);
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
  height: 110px;
}

.resultYearMonthSelector .yearMonthSelector {
  margin: 0;
}

.resultYearMonthSelector .yearTab {
  min-width: 60px;
  padding: 5px 10px;
  font-size: 12px;
}

.resultYearMonthSelector .monthGrid {
  max-width: 500px;
}

.resultYearMonthSelector .monthTile {
  width: 35px;
  height: 35px;
  font-size: 12px;
}

.resultYearMonthSelector .yearTimeline {
  max-width: 400px;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .resultHeader {
    flex-direction: column;
    gap: 10px;
  }

  .yearTimeline {
    gap: 10px;
  }
  
  .yearTab {
    padding: 7px 14px;
    min-width: 70px;
    font-size: 14px;
  }
  
  .monthTile {
    min-width: 35px;
    padding: 6px 3px;
  }
  
  .monthTile .monthNumber {
    font-size: 18px;
  }
  
  .monthTile .monthLabel {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .yearTab {
    padding: 5px 10px;
    min-width: 60px;
    font-size: 12px;
  }
  
  .monthTile {
    min-width: 30px;
    min-height: 45px;
    padding: 5px 2px;
  }
  
  .monthTile .monthNumber {
    font-size: 16px;
  }
}