/* 로딩 컨테이너 스타일 */
.diaryLoadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;
}

/* 로딩 스피너 스타일 */
.diaryLoadingSpinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

/* 로딩 텍스트 스타일 */
.diaryLoadingText {
  font-size: 1.2rem;
  color: #666;
  font-weight: 600;
  font-family: 'Nanum Gothic', sans-serif;
}

/* 스피너 애니메이션 */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 