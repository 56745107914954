.qrGenerateContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
}

.qrGenerateContainer h2 {
  text-align: center;
  margin-bottom: 20px;
  color: var(--secondary-color);
  border-bottom: 4px solid var(--primary-color);
  padding-bottom: 10px;
}

.qrGenerateLayout {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.employeeSelectionPanel {
  flex: 1;
  min-width: 300px;
  background-color: white;
  border-radius: 0;
  padding: 15px;
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.employeeSelectionPanel h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.2rem;
  color: var(--secondary-color);
}

.employeeGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 10px;
  max-height: 500px;
  overflow-y: auto;
}

.employeeCard {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 0;
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.employeeCard:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.employeeCard.selected {
  background-color: var(--accent-color);
  color: white;
  border-color: var(--border-color);
}

.employeeAvatar {
  width: 35px;
  height: 35px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
  margin-right: 8px;
  border: 2px solid var(--border-color);
}

.employeeInfo {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.employeeName {
  font-weight: bold;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}

.employeePosition {
  font-size: 0.8rem;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.8;
}

.qrDisplayPanel {
  flex: 1;
  min-width: 300px;
  background-color: white;
  border-radius: 0;
  padding: 15px;
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.qrDisplayPanel h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.2rem;
  color: var(--secondary-color);
}

.qrContent {
  background-color: white;
  border-radius: 0;
  padding: 15px;
  text-align: center;
  border: 3px solid var(--border-color);
}

.qrContent p {
  margin-bottom: 15px;
  color: var(--secondary-color);
}

.qrPlaceholder {
  width: 150px;
  height: 150px;
  margin: 0 auto 20px;
  border: 3px dashed var(--border-color);
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
}

.qrButton {
  margin-top: 15px;
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: white;
  border: 3px solid var(--border-color);
  border-radius: 0;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.qrButton:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.qrButton:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.qrButton:disabled {
  background-color: var(--secondary-color);
  opacity: 0.5;
  cursor: not-allowed;
}

.qrDownloadButton {
  margin-top: 15px;
  padding: 8px 16px;
  background-color: var(--success-color);
  color: white;
  border: 3px solid var(--border-color);
  border-radius: 0;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.qrDownloadButton:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.qrDownloadButton:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.qrInstructions {
  margin-top: 20px;
  padding: 15px;
  background-color: white;
  border-radius: 0;
  border: 3px solid var(--border-color);
}

.qrInstructions h4 {
  margin-top: 0;
  margin-bottom: 10px;
  color: var(--secondary-color);
}

.qrInstructions ol {
  padding-left: 20px;
  color: var(--secondary-color);
}

.qrInstructions li {
  margin-bottom: 5px;
}

.qrButtons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.workplaceSelector {
  margin-bottom: 15px;
  text-align: left;
}

.workplaceSelector label {
  display: block;
  margin-bottom: 5px;
  color: var(--secondary-color);
}

.workplaceSelector select {
  width: 100%;
  padding: 8px;
  border: 3px solid var(--border-color);
  border-radius: 0;
  background-color: white;
  box-shadow: 4px 4px 0 var(--shadow-color);
  font-family: inherit;
}

.workplaceSelector select:focus {
  outline: none;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}