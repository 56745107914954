:root {
  --primary-color: #ff4757;
  --secondary-color: #2f3542;
  --accent-color: #ffa502;
  --background-color: #f1f2f6;
  --border-color: #2f3542;
  --shadow-color: rgba(47, 53, 66, 0.2);
  --success-color: #2ed573;
  --warning-color: #ffa502;
  --error-color: #ff4757;
}

.evaluationModalContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
  image-rendering: pixelated;
}

/* 컨텐츠 영역 스타일 */
.evaluationContent {
  overflow-x: auto;
  height: 100%;
  padding: 15px;
}

/* 에이전트 제안 컴포넌트 스타일 */
.agentSuggestions {
  overflow-x: auto;
  height: 100%;
  padding: 15px;
}

.suggestionsTable {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border: 3px solid var(--border-color);
}

.suggestionsTable th {
  padding: 12px 15px;
  background-color: var(--primary-color);
  color: white;
  text-align: left;
  font-weight: normal;
  position: sticky;
  top: 0;
  z-index: 1;
  border: 2px solid var(--border-color);
}

.suggestionsTable td {
  padding: 12px 15px;
  border: 2px solid var(--border-color);
}

.suggestionsTable tr:nth-child(even) {
  background-color: var(--background-color);
}

.suggestionsTable tr:hover {
  background-color: var(--accent-color);
  color: white;
}

.statusBadge {
  display: inline-block;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  border: 2px solid var(--border-color);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.adopted {
  background-color: var(--success-color);
  color: white;
}

.rejected {
  background-color: var(--error-color);
  color: white;
}

.pending {
  background-color: var(--warning-color);
  color: white;
}

.btnPrimary {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: 3px solid var(--border-color);
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
  font-family: inherit;
  box-shadow: 4px 4px 0 var(--shadow-color);
  text-transform: uppercase;
}

.btnPrimary:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.btnPrimary:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

/* 스크롤바 스타일 */
.agentSuggestions::-webkit-scrollbar {
  width: 16px;
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

.agentSuggestions::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 3px solid var(--border-color);
}

.agentSuggestions::-webkit-scrollbar-track {
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .suggestionsTable {
    font-size: 12px;
  }
  
  .btnPrimary {
    width: 100%;
    margin-bottom: 10px;
  }
}