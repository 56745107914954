/* 일정 상세 패널 */
.scheduleDetailsPanel {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
}

/* 일정 목록 */
.scheduleList {
  background-color: white;
  border: 3px solid var(--border-color);
  padding: 15px;
  box-shadow: 6px 6px 0 var(--shadow-color);
  flex: 1;
  max-height: 250px;
  overflow-y: auto;
}

.scheduleList h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--secondary-color);
  border-bottom: 4px solid var(--primary-color);
  padding-bottom: 10px;
  font-size: 16px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.noSchedules {
  text-align: center;
  color: var(--secondary-color);
  padding: 20px;
  background-color: white;
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

/* 일정 카드 */
.scheduleCard {
  display: grid;
  gap: 16px;
  background-color: white;
  border: 3px solid var(--border-color);
  padding: 12px 16px;
  margin-bottom: 10px;
  box-shadow: 4px 4px 0 var(--shadow-color);
  align-items: center;
}

.scheduleCard:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.scheduleTime {
  background-color: var(--primary-color);
  color: white;
  padding: 6px 8px;
  border: 2px solid var(--border-color);
  box-shadow: 2px 2px 0 var(--shadow-color);
  text-align: center;
  font-size: 13px;
}

.scheduleTimeDivider {
  margin: 2px 0;
  opacity: 0.8;
}

.scheduleEndTime {
  font-size: 12px;
  opacity: 0.9;
}

.scheduleInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.scheduleName {
  font-size: 16px;
  font-weight: bold;
  color: var(--secondary-color);
}

.scheduleContact {
  font-size: 14px;
  color: var(--secondary-color);
}

.scheduleAmount {
  font-size: 14px;
  color: var(--accent-color);
  font-weight: bold;
}

/* 비고 영역 */
.scheduleNote {
  font-size: 13px;
  color: var(--secondary-color);
  font-style: italic;
  text-align: right;
}

/* 일정 카드에 근로자 표시 */
.scheduleEmployee {
  display: flex;
  margin-bottom: 4px;
}

.employeeBadge {
  display: inline-block;
  padding: 4px 8px;
  background-color: var(--accent-color);
  color: white;
  border: 2px solid var(--border-color);
  box-shadow: 2px 2px 0 var(--shadow-color);
  font-size: 12px;
  margin-top: 5px;
}

/* 수정/삭제 버튼 스타일링 */
.scheduleActions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.btnEditSchedule,
.btnDeleteSchedule {
  padding: 5px 12px;
  border: 3px solid var(--border-color);
  cursor: pointer;
  font-family: inherit;
  box-shadow: 2px 2px 0 var(--shadow-color);
  font-size: 12px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnEditSchedule {
  background-color: var(--warning-color);
  color: white;
}

.btnDeleteSchedule {
  background-color: var(--error-color);
  color: white;
}

.btnEditSchedule:hover,
.btnDeleteSchedule:hover {
  transform: translate(-1px, -1px);
  box-shadow: 3px 3px 0 var(--shadow-color);
}

.btnEditSchedule:active,
.btnDeleteSchedule:active {
  transform: translate(1px, 1px);
  box-shadow: 1px 1px 0 var(--shadow-color);
}

/* 스크롤바 스타일 */
.scheduleList::-webkit-scrollbar {
  width: 16px;
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

.scheduleList::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 3px solid var(--border-color);
}

.scheduleList::-webkit-scrollbar-track {
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

/* 반응형 디자인 */
@media (max-width: 1024px) {
  .scheduleCard {
    grid-template-columns: 100px minmax(150px, 1fr) 150px 140px;
    gap: 12px;
  }
}

@media (max-width: 768px) {
  .scheduleCard {
    grid-template-columns: 90px 1fr;
    gap: 8px;
  }

  .scheduleNote {
    text-align: left;
  }

  .scheduleActions {
    grid-column: 1 / -1;
    justify-content: flex-start;
  }
} 