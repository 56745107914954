/* 탭 메뉴 공통 스타일 */
.tabMenu {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
  justify-content: flex-start;
  padding: 5px;
}

.tab,
.activeTab {
  padding: 12px 20px;
  cursor: pointer;
  border: 3px solid #2f3542;
  background-color: white;
  font-family: inherit;
  font-size: 14px;
  box-shadow: 4px 4px 0 rgba(47, 53, 66, 0.2);
  transition: all 0.2s;
}

.activeTab {
  background-color: #ff4757;
  color: white;
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 rgba(47, 53, 66, 0.2);
}

.tab:hover {
  background-color: #ffa502;
  color: white;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 rgba(47, 53, 66, 0.2);
}

/* 컨텐츠 컨테이너 스타일 */
.contentContainer {
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  height: calc(100% - 60px);
  overflow-y: auto;
}

/* 스크롤바 스타일 */
.contentContainer::-webkit-scrollbar {
  width: 16px;
  background: #f1f2f6;
  border: 3px solid #2f3542;
}

.contentContainer::-webkit-scrollbar-thumb {
  background: #ff4757;
  border: 3px solid #2f3542;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .tabMenu {
    flex-wrap: wrap;
  }
  
  .tab, 
  .activeTab {
    flex-grow: 1;
    text-align: center;
  }
} 