.bizPhoneContent {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.bizPhoneTitle {
  font-size: 28px;
  margin-bottom: 30px;
  color: #333;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
}

.bizPhoneFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  align-items: flex-end;
}

.filterItem {
  flex: 1;
  min-width: 200px;
}

.filterItem label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #555;
}

.filterItem select,
.filterItem input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.filterButton {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.filterButton:hover {
  background-color: #2980b9;
}

.filterButton:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.initialMessage {
  text-align: center;
  padding: 30px;
  font-size: 16px;
  color: #666;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 20px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.loading, .errorMessage, .noPlans {
  text-align: center;
  padding: 30px;
  font-size: 16px;
  border-radius: 8px;
  margin: 20px 0;
}

.loading {
  color: #3498db;
  background-color: #f0f8ff;
}

.errorMessage {
  color: #e74c3c;
  background-color: #fef0f0;
}

.noPlans {
  color: #7f8c8d;
  background-color: #f9f9f9;
}

.plansContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.planCard {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s, box-shadow 0.3s;
}

.planCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.planHeader {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.planHeader h3 {
  font-size: 18px;
  margin: 0 0 5px 0;
  color: #333;
}

.provider {
  font-size: 14px;
  color: #3498db;
  font-weight: 500;
}

.planDetails {
  margin-bottom: 15px;
}

.detailItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.label {
  color: #7f8c8d;
}

.value {
  font-weight: 500;
  color: #333;
}

.planBenefits {
  margin-bottom: 15px;
  flex-grow: 1;
}

.planBenefits h4 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.planBenefits ul {
  padding-left: 20px;
  margin: 0;
}

.planBenefits li {
  margin-bottom: 5px;
  color: #555;
  font-size: 14px;
}

.planPrice {
  margin: 15px 0;
  text-align: center;
}

.priceValue {
  font-size: 24px;
  font-weight: bold;
  color: #3498db;
}

.priceUnit {
  font-size: 14px;
  color: #7f8c8d;
  margin-left: 5px;
}

.applyButton {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
  width: 100%;
  margin-top: 10px;
}

.applyButton:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .bizPhoneFilters {
    flex-direction: column;
  }
  
  .plansContainer {
    grid-template-columns: 1fr;
  }
}
