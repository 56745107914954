/* 다이어리 - 레트로 픽셀아트 스타일 */
.diaryContainer {
  --diary-primary-color: #000000;
  --diary-secondary-color: #d1d8e0;
  --diary-accent-color: #ff4757;
  --diary-background-color: #ffffff;
  --diary-border-color: #2f3542;
  --diary-shadow-color: rgba(0, 0, 0, 0.25);
  --diary-text-color: #000000;
  --diary-item-bg: #ffffff;
  
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  max-height: 800px;
  image-rendering: pixelated;
}

/* 픽셀 레트로 게임 배경 효과 */
@keyframes backgroundScroll {
  from { background-position: 0 0; }
  to { background-position: 40px 40px; }
}

.diaryInner {
  width: 100%;
  height: 100%;
  max-height: 780px;
  display: flex;
  position: relative;
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3);
  border: 2px solid #000000;
  overflow: hidden;
  background-color: white;
  /* 픽셀 배경 패턴 추가 */
  background-image: 
    linear-gradient(to right, rgba(200, 200, 200, 0.05) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(200, 200, 200, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
  animation: backgroundScroll 30s linear infinite;
}

/* 픽셀 아트 장식 요소 - 깜빡이는 효과 추가 */
@keyframes pixelPulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.diaryDoodle {
  position: absolute;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  image-rendering: pixelated;
  animation: pixelPulse 2s ease-in-out infinite;
}

.topLeft {
  top: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  background-color: var(--diary-accent-color);
  border: 2px solid #000000;
  animation-delay: 0s;
}

.topRight {
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: var(--diary-accent-color);
  border: 2px solid #000000;
  animation-delay: 0.5s;
}

.bottomLeft {
  bottom: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  background-color: var(--diary-accent-color);
  border: 2px solid #000000;
  animation-delay: 1s;
}

.bottomRight {
  bottom: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: var(--diary-accent-color);
  border: 2px solid #000000;
  animation-delay: 1.5s;
}

/* 텍스트 깜빡임 애니메이션 */
@keyframes textBlink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.7; }
}

.diaryHeader h2 {
  font-size: 22px;
  color: white;
  margin: 0 0 12px 0;
  padding: 10px 30px;
  position: relative;
  text-shadow: none;
  font-weight: bold;
  letter-spacing: 1px;
  background-color: var(--diary-accent-color);
  border: 2px solid #000000;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
  animation: textBlink 2s infinite;
}

/* 버튼 호버 효과 강화 - 게임 스타일 */
@keyframes buttonHover {
  0% { transform: translate(0, 0); box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3); }
  100% { transform: translate(-4px, -4px); box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.3); }
}

.monthButton:hover {
  background-color: var(--diary-accent-color);
  color: white;
  animation: buttonHover 0.2s forwards;
}

.monthButton:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
  animation: none;
}

/* 달력 날짜 선택 효과 - 픽셀 스타일 */
@keyframes daySelect {
  0% { transform: scale(1); box-shadow: none; }
  50% { transform: scale(1.02); box-shadow: 0 0 0 2px var(--diary-accent-color); }
  100% { transform: scale(1); box-shadow: 0 0 0 2px var(--diary-accent-color); }
}

.diaryDay:hover {
  background-color: #ffeef0;
  transform: translate(-2px, -2px);
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.diaryDay:active {
  animation: daySelect 0.3s forwards;
}

/* 날짜 클릭 시 깜빡임 효과 */
@keyframes dayClick {
  0% { background-color: white; }
  50% { background-color: var(--diary-accent-color); }
  100% { background-color: white; }
}

.diaryDay:active .diaryDayNumber {
  animation: dayClick 0.3s;
}

/* 데이터가 있는 날짜 표시 애니메이션 */
@keyframes dataIndicator {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
}

.hasData::after {
  content: "";
  position: absolute;
  top: 5px;
  right: 5px;
  width: 8px;
  height: 8px;
  background-color: var(--diary-accent-color);
  border-radius: 0;
  border: 1px solid #000000;
  animation: dataIndicator 2s infinite;
}

/* 게임 스타일 메모 스티커 애니메이션 */
@keyframes memoFloat {
  0% { transform: translateY(0); }
  50% { transform: translateY(-2px); }
  100% { transform: translateY(0); }
}

.memoSticker {
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: 16px;
  height: 16px;
  background-color: var(--diary-accent-color);
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: white;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
  z-index: 2;
  border: 1px solid #000000;
  animation: memoFloat 1.5s ease-in-out infinite;
}

/* 현재 월 텍스트 애니메이션 */
@keyframes monthPulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.monthBubble {
  background-color: white;
  padding: 8px 20px;
  border-radius: 0;
  font-weight: bold;
  color: #000000;
  font-size: 14px;
  position: relative;
  border: 2px solid #000000;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
  animation: monthPulse 3s ease-in-out infinite;
}

/* 게임 로딩 효과 - 도트 그라데이션 */
@keyframes dotLoading {
  0% { background-position: 0% 0%; }
  100% { background-position: 100% 0%; }
}

.diaryCalendar::before {
  content: '';
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  z-index: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath d='M5,5 L25,5 L25,25 L5,25 Z' stroke='%23ff4757' stroke-width='2' fill='none' stroke-dasharray='3,2'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  animation: rotate 6s linear infinite;
}

/* 픽셀 도트 패턴 움직임 */
@keyframes dotMovement {
  0% { transform: rotate(-15deg) translateX(0); }
  50% { transform: rotate(-15deg) translateX(5px); }
  100% { transform: rotate(-15deg) translateX(0); }
}

.dottedPattern {
  position: absolute;
  width: 100px;
  height: 100px;
  opacity: 0.1;
  background-image: radial-gradient(#000 1px, transparent 1px);
  background-size: 10px 10px;
  pointer-events: none;
  z-index: 0;
}

.dottedPattern.topLeft {
  top: 20px;
  left: 20px;
  transform: rotate(-15deg);
  animation: dotMovement 5s ease-in-out infinite;
}

.dottedPattern.bottomRight {
  bottom: 20px;
  right: 20px;
  transform: rotate(15deg);
  animation: dotMovement 5s ease-in-out infinite reverse;
}

.diaryContent {
  flex: 1;
  padding: 15px 20px;
  overflow-y: auto;
  position: relative;
  background-image: none;
  background-color: #ffffff;
  z-index: 2;
  height: 90%;
  border-right: 2px solid #000000;
}

.diaryContent::-webkit-scrollbar {
  width: 10px;
  background-color: #e6e6e6;
  border-left: 2px solid #000000;
}

.diaryContent::-webkit-scrollbar-thumb {
  background-color: #454545;
  border: 2px solid #e6e6e6;
}

.diaryContent::-webkit-scrollbar-button {
  background-color: #e6e6e6;
  height: 10px;
  border: 1px solid #000000;
}

.diaryContent::-webkit-scrollbar-button:vertical:start {
  background-image: linear-gradient(to top, #000000 40%, transparent 40%);
  background-size: 6px 6px;
  background-repeat: no-repeat;
  background-position: center;
}

.diaryContent::-webkit-scrollbar-button:vertical:end {
  background-image: linear-gradient(to bottom, #000000 40%, transparent 40%);
  background-size: 6px 6px;
  background-repeat: no-repeat;
  background-position: center;
}

.diaryHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 8px;
  position: relative;
  border-bottom: 2px solid #000000;
}

.diaryHeaderDecoration {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  display: none;
}

.diaryMonthSelector {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
  width: 100%;
  justify-content: center;
}

.monthButton {
  background-color: white;
  border: 2px solid #000000;
  padding: 6px 12px;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: #000000;
  font-weight: bold;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
}

.arrowIcon {
  font-size: 10px;
}

.currentMonth {
  position: relative;
  padding: 0 15px;
}

.monthBubble::before,
.monthBubble::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  background-color: var(--diary-accent-color);
  border: 1px solid #000000;
}

.monthBubble::before {
  left: 8px;
}

.monthBubble::after {
  right: 8px;
}

/* 달력 스타일 업데이트 */
.diaryCalendarWrapper {
  position: relative;
  margin-bottom: 10px;
  z-index: 1;
}

.diaryCalendar {
  position: relative;
  background-color: #fff;
  border-radius: 0;
  padding: 6px 8px;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
  max-width: 100%;
  max-height: 58vh; /* 높이 더 조정 */
  overflow: auto;
  border: 2px solid #000000;
  animation: slideIn 0.7s ease-out forwards;
  z-index: 2;
}

.diaryCalendar::-webkit-scrollbar {
  width: 10px;
  background-color: #e6e6e6;
  border-left: 2px solid #000000;
}

.diaryCalendar::-webkit-scrollbar-thumb {
  background-color: #454545;
  border: 2px solid #e6e6e6;
}

.diaryCalendar::-webkit-scrollbar-button {
  background-color: #e6e6e6;
  height: 10px;
  border: 1px solid #000000;
}

.diaryWeekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
  color: #000000;
  background-color: var(--diary-accent-color);
  color: white;
  border: 2px solid #000000;
}

.diaryWeekday {
  padding: 3px 0;
  font-size: 0.8rem;
  animation: none;
}

.sunday {
  color: #ff5555;
}

.saturday {
  color: #5579ff;
}

.diaryDays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 2px;
  padding: 10px;
  background-color: #f0f0f0;
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  border: 2px solid #000000;
}

.diaryDay {
  position: relative;
  height: 70px; /* 날짜 셀 높이 조정 */
  padding: 2px 1px;
  background-color: #fff;
  transition: all 0.2s ease;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #000000;
}

.otherMonth {
  opacity: 0.5;
  background-color: #f0f0f0;
}

.sunday .diaryDayNumber {
  color: #ff5555;
}

.saturday .diaryDayNumber {
  color: #5579ff;
}

.hasData {
  position: relative;
  border: 2px solid var(--diary-accent-color);
}

.diaryDayNumber {
  font-size: 12px;
  font-weight: bold;
  padding: 2px 4px;
  background-color: #f8f8f8;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  user-select: none;
  border-color: #000000;
}

.diaryDayContent {
  font-size: 10px;
  padding: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  max-height: 40px; /* 높이 조정 */
  word-break: break-all;
  height: calc(100% - 22px);
}

.diaryIconBubble {
  display: inline-block;
  font-size: 8px;
  margin-right: 4px;
  margin-bottom: 2px;
  padding: 2px 4px;
  border-radius: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  border: 1px solid #000000;
}

.salesBubble {
  background-color: #e3f2fd;
  color: #0d47a1;
  border-color: #0d47a1;
}

.expenseBubble {
  background-color: #ffebee;
  color: #b71c1c;
  border-color: #b71c1c;
}

.memoSticker::before {
  content: "📝";
  font-size: 10px;
}

@media (max-width: 768px) {
  .diaryFormRow {
    flex-direction: column;
    gap: 10px;
  }
  
  .diaryDay {
    height: 60px;
  }
  
  .diaryDayNumber {
    font-size: 10px;
    padding: 1px 3px;
  }
  
  .diaryInner {
    flex-direction: column;
  }
  
  .diaryBookmark {
    width: 100%;
    height: auto;
    min-height: 50px;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }
  
  .bookmarkItem {
    flex-direction: row;
    padding: 8px;
    margin-bottom: 0;
    text-align: center;
  }
  
  .bookmarkItem span {
    margin-left: 5px;
  }
  
  .bookmarkItem.active {
    padding-right: 12px;
  }
  
  .diaryContent {
    padding: 10px;
  }
  
  .diaryBookmark {
    padding: 8px;
  }
  
  .diaryHeader h2 {
    font-size: 18px;
  }
  
  .monthBubble {
    font-size: 12px;
    padding: 6px 15px;
  }
  
  .diaryIconBubble, .memoSticker {
    font-size: 7px;
    padding: 1px 2px;
  }
  
  .diaryDayContent {
    -webkit-line-clamp: 2;
    line-clamp: 2;
    max-height: 24px;
    font-size: 9px;
  }
}

/* 화면 높이가 작을 때 */
@media (max-height: 750px) {
  .diaryDay {
    height: 45px; /* 더 줄임 */
  }
  
  .diaryHeader h2 {
    font-size: 16px;
  }
  
  .monthBubble {
    padding: 5px 12px;
  }
  
  .bookmarkItem {
    padding: 8px 5px;
  }
  
  .diaryCalendar {
    max-height: 55vh; /* 높이 더 조정 */
    padding: 4px 6px;
  }
  
  .diaryDayContent {
    max-height: 26px;
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
  
  .diaryMonthSelector {
    margin-bottom: 10px;
  }
}

/* 8비트 사운드 효과처럼 보이는 시각적 표현 */
@keyframes soundWave {
  0% { box-shadow: 0 0 0 0 rgba(255, 71, 87, 0.4); }
  70% { box-shadow: 0 0 0 6px rgba(255, 71, 87, 0); }
  100% { box-shadow: 0 0 0 0 rgba(255, 71, 87, 0); }
}

/* 픽셀 깜빡임 효과 - CRT 모니터 같은 느낌 */
@keyframes pixelFlicker {
  0%, 100% { opacity: 1; }
  3% { opacity: 0.8; }
  6% { opacity: 1; }
  7% { opacity: 0.9; }
  8% { opacity: 1; }
  9% { opacity: 1; }
  10% { opacity: 0.9; }
  20% { opacity: 1; }
  50% { opacity: 0.8; }
  60% { opacity: 1; }
  90% { opacity: 0.9; }
}

/* 게임 시작 화면 같은 깜빡임 */
.diaryHeader {
  position: relative;
}

.diaryHeader::after {
  content: "PRESS START";
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  color: var(--diary-accent-color);
  font-weight: bold;
  animation: pixelFlicker 2s infinite;
  opacity: 0.8;
  pointer-events: none;
}

/* 게임 보더 스타일 - 레트로 게임 프레임 (글로우 효과 제거) */
.diaryContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 4px solid var(--diary-accent-color);
  pointer-events: none;
  z-index: 5;
  opacity: 0;
  animation: none;
}

/* 오래된 게임기 화면 스캔라인 효과 */
@keyframes scanlines {
  0% { background-position: 0 0; }
  100% { background-position: 0 100px; }
}

.diaryCalendar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-image: linear-gradient(
    transparent 50%, 
    rgba(0, 0, 0, 0.02) 50%
  );
  background-size: 100% 4px;
  opacity: 0.3;
  animation: scanlines 1s linear infinite;
  z-index: 1;
}

/* 게임 아이템 발견 효과 */
@keyframes itemFound {
  0% { transform: scale(1); }
  40% { transform: scale(1.2); }
  60% { transform: scale(0.9); }
  80% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.hasData:hover::after {
  animation: itemFound 0.5s ease-in-out forwards, dataIndicator 2s infinite;
}

/* 픽셀 폰트 글로우 효과 */
@keyframes textGlow {
  0%, 100% { text-shadow: 0 0 2px rgba(255, 71, 87, 0.7); }
  50% { text-shadow: 0 0 10px rgba(255, 71, 87, 0.9); }
}

.diaryWeekday {
  padding: 3px 0;
  font-size: 0.8rem;
  animation: none;
}

/* 게임 UI 요소처럼 들어오는 애니메이션 */
@keyframes slideIn {
  0% { transform: translateY(-10px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

.diaryMonthSelector {
  animation: slideIn 0.5s ease-out forwards;
}

.diaryCalendar {
  animation: slideIn 0.7s ease-out forwards;
}

/* 게임 로고 반짝임 효과 */
@keyframes logoShine {
  0% { background-position: -100% 0; }
  100% { background-position: 200% 0; }
}

.diaryHeader h2::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  background-size: 200% 100%;
  animation: logoShine 2s linear infinite;
  z-index: 1;
  pointer-events: none;
}

/* SVG 코너 장식 추가 */
.diaryCalendar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 2;
  background-image: 
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M1,1 L10,1 L1,10 Z' fill='%23ff4757'/%3E%3C/svg%3E"),
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M19,1 L19,10 L10,1 Z' fill='%23ff4757'/%3E%3C/svg%3E"),
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M1,19 L1,10 L10,19 Z' fill='%23ff4757'/%3E%3C/svg%3E"),
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M19,19 L10,19 L19,10 Z' fill='%23ff4757'/%3E%3C/svg%3E");
  background-position: 
    top left,
    top right,
    bottom left,
    bottom right;
  background-repeat: no-repeat;
  border: none;
}

/* 픽셀 애니메이션 테두리 */
.diaryCalendarBorder {
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  z-index: 1;
  pointer-events: none;
}

/* SVG 애니메이션 */
@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 회전하는 픽셀 테두리 장식 */
.diaryCalendarWrapper::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border: 2px dashed var(--diary-accent-color);
  z-index: 0;
  animation: borderDance 10s linear infinite;
  pointer-events: none;
}

/* 픽셀 테두리 댄스 애니메이션 */
@keyframes borderDance {
  0% { 
    clip-path: polygon(
      0% 0%, 100% 0%, 100% 8%, 0% 8%, 
      0% 92%, 100% 92%, 100% 100%, 0% 100%,
      0% 0%, 8% 0%, 8% 100%, 0% 100%,
      92% 0%, 100% 0%, 100% 100%, 92% 100%
    );
  }
  25% { 
    clip-path: polygon(
      0% 0%, 100% 0%, 100% 12%, 0% 12%, 
      0% 88%, 100% 88%, 100% 100%, 0% 100%,
      0% 0%, 12% 0%, 12% 100%, 0% 100%,
      88% 0%, 100% 0%, 100% 100%, 88% 100%
    );
  }
  50% { 
    clip-path: polygon(
      0% 0%, 100% 0%, 100% 5%, 0% 5%, 
      0% 95%, 100% 95%, 100% 100%, 0% 100%,
      0% 0%, 5% 0%, 5% 100%, 0% 100%,
      95% 0%, 100% 0%, 100% 100%, 95% 100%
    );
  }
  75% { 
    clip-path: polygon(
      0% 0%, 100% 0%, 100% 12%, 0% 12%, 
      0% 88%, 100% 88%, 100% 100%, 0% 100%,
      0% 0%, 12% 0%, 12% 100%, 0% 100%,
      88% 0%, 100% 0%, 100% 100%, 88% 100%
    );
  }
  100% { 
    clip-path: polygon(
      0% 0%, 100% 0%, 100% 8%, 0% 8%, 
      0% 92%, 100% 92%, 100% 100%, 0% 100%,
      0% 0%, 8% 0%, 8% 100%, 0% 100%,
      92% 0%, 100% 0%, 100% 100%, 92% 100%
    );
  }
}

/* 중복된 스캔라인 효과 클래스 제거 */
.diaryCalendar .scanlineEffect {
  display: none;
}