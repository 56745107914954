.bizAnalysisContent {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.analysisTitle {
  font-size: 28px;
  margin-bottom: 30px;
  color: #333;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
}

.analysisFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  align-items: flex-end;
}

.filterSection {
  flex: 1;
  min-width: 200px;
}

.filterSection h3 {
  margin-bottom: 10px;
  font-size: 16px;
  color: #555;
}

.areaSelect, .categorySelect {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.analysisButton {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.analysisButton:hover {
  background-color: #2980b9;
}

.analysisButton:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.analysisResults {
  margin-top: 40px;
}

.analysisResults h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.resultsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.resultCard {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.resultCard h3 {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.resultValue {
  font-size: 24px;
  font-weight: bold;
  color: #3498db;
  margin-bottom: 5px;
}

.resultDesc {
  font-size: 14px;
  color: #777;
}

.analysisChart {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.chartPlaceholder {
  height: 300px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ddd;
  border-radius: 4px;
}

.analysisRecommendations {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.analysisRecommendations h3 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #333;
}

.analysisRecommendations ul {
  padding-left: 20px;
}

.analysisRecommendations li {
  margin-bottom: 10px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .analysisFilters {
    flex-direction: column;
  }
  
  .resultsGrid {
    grid-template-columns: 1fr;
  }
}
