.businessReport {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
}

.reportHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.reportHeader h2 {
  margin: 0;
  color: var(--secondary-color);
}

.reportTabs {
  display: flex;
  gap: 10px;
}

.reportTabs button {
  padding: 12px 24px;
  border: 3px solid var(--border-color);
  background-color: white;
  color: var(--secondary-color);
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: all 0.2s;
}

.reportTabs button:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.activeTab {
  background-color: var(--primary-color) !important;
  color: white !important;
  transform: translate(2px, 2px) !important;
  box-shadow: 2px 2px 0 var(--shadow-color) !important;
}

.reportContainer {
  flex: 1;
  overflow-y: auto;
  background-color: var(--background-color);
  border: 4px solid var(--border-color);
  padding: 20px;
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.reportSection {
  background-color: white;
  border: 3px solid var(--border-color);
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.reportSection h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--secondary-color);
  border-bottom: 4px solid var(--primary-color);
  padding-bottom: 10px;
  font-size: 16px;
}

.reportMetrics {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.metricCard {
  background-color: white;
  border: 3px solid var(--border-color);
  padding: 15px;
  flex: 1;
  min-width: 180px;
  text-align: center;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.metricCard.highlight {
  background-color: var(--primary-color);
  color: white;
}

.metricCard h4 {
  margin: 0 0 10px 0;
  color: var(--secondary-color);
  font-size: 14px;
}

.highlight h4 {
  color: white;
}

.metricValue {
  font-size: 24px;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 5px;
}

.highlight .metricValue {
  color: white;
}

.subText {
  font-size: 12px;
  color: var(--secondary-color);
}

.progressBar {
  height: 10px;
  background-color: white;
  border: 2px solid var(--border-color);
  margin-top: 10px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: var(--success-color);
}

.reportRow {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.halfWidth {
  flex: 1;
}

.chartContainer {
  padding: 10px 0;
}

.barChart {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chartItem {
  display: flex;
  align-items: center;
}

.barLabel {
  width: 120px;
  font-size: 14px;
  color: var(--secondary-color);
}

.barContainer {
  flex: 1;
  height: 24px;
  background-color: white;
  border: 2px solid var(--border-color);
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
}

.bar {
  height: 100%;
  transition: width 0.5s;
}

.barValue {
  position: absolute;
  right: 10px;
  font-size: 12px;
  color: var(--secondary-color);
  z-index: 1;
}

.topCustomersTable {
  width: 100%;
  border-collapse: collapse;
  border: 3px solid var(--border-color);
}

.topCustomersTable th,
.topCustomersTable td {
  padding: 10px 15px;
  text-align: left;
  border: 2px solid var(--border-color);
}

.topCustomersTable th {
  background-color: var(--primary-color);
  color: white;
}

.topCustomersTable tr:nth-child(even) {
  background-color: var(--background-color);
}

.categoryBadge {
  display: inline-block;
  padding: 4px 8px;
  border: 2px solid var(--border-color);
  font-size: 12px;
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.noDataMessage {
  padding: 30px;
  text-align: center;
  color: var(--secondary-color);
  background-color: white;
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.reportFooter {
  margin-top: 20px;
  background-color: white;
  border: 3px solid var(--border-color);
  padding: 20px;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.reportSummary h3 {
  margin-top: 0;
  margin-bottom: 10px;
  color: var(--secondary-color);
  font-size: 16px;
}

.insights p {
  margin: 8px 0;
  font-size: 14px;
  line-height: 1.5;
  color: var(--secondary-color);
}

.btnPrimary {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: 3px solid var(--border-color);
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  box-shadow: 4px 4px 0 var(--shadow-color);
  text-transform: uppercase;
}

.btnPrimary:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.btnPrimary:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

/* 스크롤바 스타일 */
.reportContainer::-webkit-scrollbar {
  width: 16px;
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

.reportContainer::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 3px solid var(--border-color);
}

.reportContainer::-webkit-scrollbar-track {
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

@media (max-width: 768px) {
  .reportRow {
    flex-direction: column;
  }
  
  .reportHeader {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .reportTabs {
    margin-top: 10px;
    width: 100%;
  }
  
  .reportTabs button {
    flex: 1;
    padding: 8px 12px;
    font-size: 12px;
  }
  
  .reportFooter {
    flex-direction: column;
    gap: 15px;
  }
  
  .metricCard {
    min-width: 100%;
  }
} 