.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  max-width: 1920px;
  max-height: 1080px;
  margin: 0 auto;
  overflow: hidden;
}

.leftSection {
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
}

.interiorSection {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 15px;
}

.interiorDisplay {
  background-color: #ffffff;
  border: 2px solid #000000;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
  padding: 8px;
  position: relative;
  width: 100%;
  image-rendering: pixelated;
}

.interiorDisplay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px dashed #000000;
  margin: 8px;
  pointer-events: none;
}

.interiorDisplay::after {
  content: "";
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  background-color: #000000;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.statsSection {
  border: 2px solid #000000;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
  padding: 8px;
  position: relative;
  flex: 1;
  min-height: 280px;
  width: 100%;
  background-color: #ffffff;
}

.statsSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px dashed #000000;
  margin: 8px;
  pointer-events: none;
}

.statsSection::after {
  content: "";
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  background-color: #000000;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.rightMenu {
  flex: 0 0 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* 모바일 반응형 레이아웃 */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    height: auto;
    overflow-y: auto;
  }

  .leftSection {
    flex: none;
    width: 100%;
    order: 1;
  }

  .interiorSection {
    width: 100%;
    order: 2;
    margin: 10px 0;
  }

  .rightMenu {
    flex: none;
    width: 100%;
    order: 3;
  }
}

/* 더 작은 모바일 화면에 대한 추가 조정 */
@media (max-width: 480px) {
  .interiorDisplay, .statsSection {
    padding: 5px;
  }
  
  .interiorDisplay::before, .statsSection::before {
    margin: 5px;
  }
}

/* 배너 섹션 스타일 */
.bannerSection {
  width: 100%;
  margin-top: 15px;
}

.bannerImage {
  width: 100%;
  height: auto;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
  border: 2px solid #000000;
} 