.insuranceModal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-family: 'Noto Sans KR', sans-serif;
}

.insuranceContent {
  width: 100%;
  max-width: 800px;
  text-align: center;
}

.insuranceContent h2 {
  color: #333;
  margin-bottom: 20px;
}

.insuranceContent p {
  color: #666;
  margin-bottom: 30px;
}

.kakaoConsultBtn {
  background-color: #FEE500;
  border: none;
  border-radius: 8px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  margin-bottom: 40px;
  transition: transform 0.2s;
}

.kakaoConsultBtn:hover {
  transform: translateY(-2px);
}

.infoContainer {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.businessCard, .adBanner {
  flex: 1;
  max-width: 300px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  background-color: white;
}

.businessCard {
  text-align: left;
}

.businessCard h3, .adBanner h3 {
  color: #333;
  margin-bottom: 15px;
  font-size: 16px;
}

.cardContent {
  display: flex;
  align-items: center;
}

.consultantInfo p {
  margin: 5px 0;
}

.consultantInfo .name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.consultantInfo .title {
  font-size: 14px;
  color: #666;
}

.consultantInfo .contact {
  font-size: 14px;
  color: #333;
}

.adBanner {
  background: linear-gradient(135deg, #ff3333 0%, #ff6666 100%);
  color: white;
}

.bannerContent {
  text-align: center;
}

.bannerContent h3 {
  color: white;
}

.bannerContent p {
  margin: 10px 0;
  color: white;
}

.promotion {
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .infoContainer {
    flex-direction: column;
    align-items: center;
  }
  
  .businessCard, .adBanner {
    width: 100%;
    max-width: none;
  }
} 