.mainMenu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  max-width: 600px;
  min-height: 400px;
  width: 100%;
  padding: 16px;
  image-rendering: pixelated;
  position: relative;
}

.menuItem {
  box-sizing: border-box;
  padding: 15px;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  min-width: 80px;
  min-height: 80px;
  width: 100%;
  height: 0;
  padding-bottom: 25%;
  flex-direction: column;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  transition: all 0.2s ease;
  border: none;
  image-rendering: pixelated;
  background-color: #e1d5c8;
  color: #382920;
  box-shadow: 4px 4px 0px #382920;
  border: 1px solid #382920;
}

.menuItem:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0px #382920;
}

.menuItem:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0px #382920;
}

.menuItemBountyQuest,
.menuItemBountyPosting {
  background-color: #d1b499;
  color: #382920;
}

.menuItemNews,
.menuItemMyNews {
  background-color: #c1aa97;
  color: #382920;
}

.menuItemAttendance,
.menuItemManagement,
.menuItemTools {
  background-color: #e1d5c8;
  color: #382920;
}

.menuItemDiary,
.menuItemCommunity,
.menuItemAgent {
  background-color: #f2eadf;
  color: #382920;
}

.logo {
  padding: 5px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  image-rendering: pixelated;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: floating 3s ease-in-out infinite;
}

.logo::after {
  display: none; /* 도트 장식 제거 */
}

/* 책장 질감 */
.mainMenu::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

/* 빈티지 도트 장식, 테두리 점선 */
.menuItem::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border: 1px dotted #795c48;
  pointer-events: none;
}

.wave {
  display: none;
}

/* 그룹 1: 바운티 관련 (바운티 퀘스트, 바운티 포스팅) 
   - 차분한 다이아몬드 형태 패턴 적용 */
.menuItemBountyQuest,
.menuItemBountyPosting {
  background-color: #1E90FF;
  color: #FFFFFF;
  background-image: 
    linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%),
    linear-gradient(-45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%);
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
}

/* 그룹 2: 뉴스 관련 (오늘의 소식, 나의 소식) 
   - 파스텔 오렌지 계열 배경에 원형 무늬 패턴 적용 */
.menuItemNews,
.menuItemMyNews {
  background-color: #FF4500;
  color: #FFFFFF;
  background-image: radial-gradient(circle at 20px 20px, rgba(255,255,255,0.4) 2px, transparent 0), 
                    radial-gradient(circle at 60px 60px, rgba(255,255,255,0.4) 2px, transparent 0);
  background-size: 80px 80px;
}

/* 그룹 3: 관리 관련 (출결관리, 운영관리) 
   - 파스텔 그린 계열 배경에 체크무늬 패턴 적용 */
.menuItemAttendance,
.menuItemManagement,
.menuItemTools {
  background-color: #008000;
  color: #FFFFFF;
  background-image: linear-gradient(0deg, transparent 24%, rgba(255,255,255,0.3) 25%, rgba(255,255,255,0.3) 26%, transparent 27%, transparent),
                    linear-gradient(90deg, transparent 24%, rgba(255,255,255,0.3) 25%, rgba(255,255,255,0.3) 26%, transparent 27%, transparent);
  background-size: 50px 50px;
}

/* 그룹 4: 그 외 (다이어리, 커뮤니티, 에이전트) 
   - 차분한 미세 그리드 패턴 적용 */
.menuItemDiary,
.menuItemCommunity,
.menuItemAgent {
  background-color: #FF69B4;
  color: #FFFFFF;
  background-image: 
    linear-gradient(0deg, rgba(255, 255, 255, 0.06) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.06) 1px, transparent 1px);
  background-size: 20px 20px;
}

/* 로고 스타일 */
.logo svg {
  width: 80%;
  height: 80%;
}

/* 투명한 메뉴 항목 */
.menuItem.transparent {
  background-color: transparent;
  box-shadow: none;
  cursor: default;
}

/* 원형 물결 효과 */
.menuItem .wave {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.5);
  transform: translate(-50%, -50%) scale(0);
  opacity: 1;
  pointer-events: none;
}

/* hover 시 원형 물결 애니메이션 */
.menuItem:hover .wave {
  animation: ripple 1.5s ease-out forwards;
}

@keyframes ripple {
  to {
    transform: translate(-50%, -50%) scale(15);
    opacity: 0;
  }
}

/* 다이어리 버튼만을 위한 특별 스타일 */
.menuItemDiary {
  background-color: #8A2BE2; /* 더 눈에 띄는 보라색 배경 */
  color: #FFFFFF;
  box-shadow: 0 0 15px rgba(138, 43, 226, 0.6);
  position: relative;
  overflow: hidden;
  transform: scale(1.00); /* 항상 확대된 상태 유지 */
  background-size: 30px 30px;
  animation: diaryPulse 2s infinite alternate;
}

.menuItemDiary::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  border-radius: 7px;
  background: linear-gradient(45deg, #ff00ff, #00ffff, #ff00ff, #00ffff);
  background-size: 400%;
  animation: borderGlow 3s linear infinite;
}

/* 다이어리 버튼 호버 효과 제거 (transform이 이미 적용되어 있으므로) */
.menuItemDiary:hover {
  transform: scale(1.03); /* 이미 확대된 상태 유지 (변화 없음) */
}

/* 다이어리 물결 효과 특별 스타일 */
.menuItemDiary .wave {
  background: rgba(255, 255, 255, 0.7);
}

@keyframes borderGlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes diaryPulse {
  0% {
    box-shadow: 0 0 15px rgba(138, 43, 226, 0.6);
  }
  100% {
    box-shadow: 0 0 25px rgba(255, 105, 180, 0.8);
  }
}

/* @keyframes floating {
  0% {
    transform: translateY(0px) rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: translateY(-10px) rotate(3deg);
    opacity: 0.85;
  }
  100% {
    transform: translateY(0px) rotate(0deg);
    opacity: 1;
  }
} */
