.authTabsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background-color: #1a1a2e;
    background-image: 
      linear-gradient(0deg, rgba(30, 30, 60, 0.5) 50%, transparent 50%),
      linear-gradient(0deg, rgba(20, 20, 45, 0.4) 40%, transparent 40%),
      linear-gradient(0deg, rgba(10, 10, 30, 0.3) 30%, transparent 30%),
      linear-gradient(180deg, #ff9e7d 0%, #ff5555 8%, #472e59 30%, #16213e 60%);
    background-size: 8px 8px, 12px 12px, 16px 16px, 100% 100%;
    background-position: 0 90%, 4px 92%, 8px 94%, 0 0;
    background-repeat: repeat-x, repeat-x, repeat-x, no-repeat;
  }
  
  .retroTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .pixelText {
    font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
    color: #333399;
    font-size: 16px;
    letter-spacing: 1px;
    text-shadow: 2px 2px 0 #91d6ff;
    margin-bottom: 15px;
    padding: 10px 15px;
    image-rendering: pixelated;
    -webkit-font-smoothing: none;
    background-color: #ffffff;
    border: 4px solid #333399;
    border-radius: 8px;
    box-shadow: 0 5px 0 #333399, 0 8px 15px rgba(0, 0, 0, 0.2);
  }
  
  .pixelTextLarge {
    font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
    color: #ff5555;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 2px;
    text-shadow: 3px 3px 0 #ffffff;
    margin: 10px 0;
    padding: 15px 20px;
    image-rendering: pixelated;
    -webkit-font-smoothing: none;
    background-color: #ffffff;
    border: 5px solid #ff5555;
    border-radius: 8px;
    box-shadow: 0 6px 0 #ff5555, 0 10px 20px rgba(0, 0, 0, 0.2);
    animation: float 3s ease-in-out infinite;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .tabButton {
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    background-color: #FFFFFF;
    color: #333399;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: 0 3px 0 #333399, 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .tabButton.active {
    background-color: #333399;
    color: #FFFFFF;
  }
  
  .tabButton:hover {
    background-color: #eeeeff;
    transform: translateY(-2px);
    box-shadow: 0 5px 0 #333399, 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .tabContent {
    width: 300px;
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }
  
  /* 모바일 화면 대응 */
  @media screen and (max-width: 480px) {
    .pixelText {
      font-size: 14px;
      padding: 5px;
      width: 100%;
      text-align: center;
    }
    
    .pixelTextLarge {
      font-size: 22px;
      padding: 8px 12px;
      width: 100%;
      text-align: center;
    }
    
    .tabContent {
      width: 90%;
      max-width: 300px;
    }
  }