.newsContainer {
  padding: 10px;
  max-width: 1800px;
  margin: 0 auto;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
}

.newsContainer h2 {
  text-align: center;
  color: var(--secondary-color);
  margin-bottom: 15px;
  font-size: 1.5rem;
  border-bottom: 4px solid var(--primary-color);
  padding-bottom: 10px;
}

.categoryButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 15px;
  justify-content: center;
}

.categoryButton {
  padding: 6px 12px;
  border: 3px solid var(--border-color);
  border-radius: 0;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 13px;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.categoryButton:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.categoryButton:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.categoryButton.active {
  background-color: var(--primary-color);
  color: white;
}

.newsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 10px;
  padding: 10px;
}

.newsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 8px;
  background: white;
  border-radius: 0;
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: transform 0.2s, box-shadow 0.2s;
  text-decoration: none;
  color: var(--secondary-color);
  height: 100%;
  min-height: 70px;
}

.newsItem:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.newsIcon {
  font-size: 18px;
  margin-bottom: 6px;
}

.newsName {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
}

@media (min-width: 1600px) {
  .newsGrid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}

@media (max-width: 768px) {
  .newsGrid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
  }
} 