:root {
  --primary-color: #ff4757;
  --secondary-color: #2f3542;
  --accent-color: #ffa502;
  --background-color: #f1f2f6;
  --border-color: #2f3542;
  --shadow-color: rgba(47, 53, 66, 0.2);
  --success-color: #2ed573;
  --warning-color: #ffa502;
  --error-color: #ff4757;
}

.payrollDetails {
  padding: 15px;
  background-color: white;
  border: 4px solid var(--border-color);
  box-shadow: 6px 6px 0 var(--shadow-color);
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
  overflow: visible;
  flex-grow: 1;
}

.detailsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  margin-bottom: 12px;
  width: 100%;
}

.basicInfoColumn {
  background-color: var(--background-color);
  border: 3px solid var(--border-color);
  padding: 12px;
  overflow-y: auto;
  max-height: 500px;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.basicInfoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-bottom: 12px;
}

.allowanceInfoColumn,
.additionalAllowanceColumn {
  background-color: var(--background-color);
  border: 3px solid var(--border-color);
  padding: 12px;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

/* 제목 스타일 통일 */
.basicInfoColumn h4,
.allowanceInfoColumn h4,
.additionalAllowanceColumn h4 {
  font-size: 14px;
  color: var(--primary-color);
  margin: 0 0 12px 0;
  border-bottom: 3px solid var(--border-color);
  padding-bottom: 6px;
  text-transform: uppercase;
}

.basicInfoItem,
.allowanceInfoItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
  padding: 6px;
  background: white;
  border: 2px solid var(--border-color);
}

.basicInfoLabel,
.allowanceLabel {
  font-size: 12px;
  color: var(--secondary-color);
  flex: 1;
}

.basicInfoValue,
.allowanceValue {
  font-weight: bold;
  font-size: 12px;
  color: var(--secondary-color);
}

.allowanceGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 6px;
  margin-bottom: 12px;
}

.summarySection {
  background-color: var(--background-color);
  border: 4px solid var(--border-color);
  padding: 15px;
  margin: 15px 0;
  box-shadow: 5px 5px 0 var(--shadow-color);
}

.summaryRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.summaryGroup {
  flex: 1;
  min-width: 150px;
  padding: 10px;
  margin: 5px;
  background-color: white;
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.summaryGroupTitle {
  font-size: 12px;
  color: var(--secondary-color);
  margin-bottom: 8px;
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 4px;
}

.summaryGroupValue {
  font-weight: bold;
  font-size: 14px;
  color: var(--primary-color);
  text-align: right;
}

.summaryGroupItem {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 4px;
  padding: 4px;
  border: 2px solid var(--border-color);
  background: white;
}

.highlightGroup {
  background-color: var(--accent-color);
  border-color: var(--secondary-color);
}

.highlightGroup .summaryGroupTitle {
  color: white;
}

.highlightGroup .summaryGroupValue {
  color: white;
  font-size: 16px;
}

/* 입력 필드 스타일 */
.allowanceInputWrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.allowanceInput {
  width: 100%;
  font-size: 11px;
  padding: 4px 6px;
  border: 2px solid var(--border-color);
  text-align: right;
  background-color: white;
  font-weight: bold;
  color: var(--secondary-color);
  box-shadow: 2px 2px 0 var(--shadow-color);
  font-family: inherit;
  height: 28px;
  box-sizing: border-box;
}

.allowanceInput:disabled {
  background-color: var(--background-color);
  color: var(--secondary-color);
  opacity: 0.7;
}

.allowanceInput:focus {
  outline: none;
  transform: translate(-2px, -2px);
  box-shadow: 5px 5px 0 var(--shadow-color);
}

.currencyUnit {
  margin-left: 4px;
  font-size: 11px;
  color: var(--secondary-color);
}

/* 버튼 스타일 */
.actionsSection {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.actionButton {
  padding: 8px 16px;
  border: 3px solid var(--border-color);
  font-family: inherit;
  cursor: pointer;
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: all 0.2s;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.actionButton:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.actionButton:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.saveButton {
  background-color: var(--warning-color);
  color: white;
}

.completeButton {
  background-color: var(--success-color);
  color: white;
}

.printButton {
  background-color: var(--accent-color);
  color: white;
}

.success {
  background-color: var(--success-color);
}

.error {
  background-color: var(--error-color);
}

/* 주간 정보 스타일 */
.weeklyTitle {
  font-size: 14px;
  color: var(--primary-color);
  margin: 15px 0 10px;
  border-bottom: 3px solid var(--border-color);
  padding-bottom: 6px;
}

.weeklyInfoBox {
  background-color: white;
  border: 3px solid var(--border-color);
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.weeklyInfoHeader {
  font-weight: bold;
  font-size: 12px;
  color: var(--primary-color);
  margin-bottom: 8px;
  padding-bottom: 4px;
  border-bottom: 2px solid var(--border-color);
}

.weeklyInfoItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  padding: 4px;
  border: 2px solid var(--border-color);
  background: var(--background-color);
}

.weeklyInfoLabel {
  font-size: 11px;
  color: var(--secondary-color);
}

.weeklyInfoValue {
  font-weight: bold;
  font-size: 11px;
  color: var(--secondary-color);
}

/* 설정 그리드 */
.employeeSettingsGrid,
.insuranceStatusGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-bottom: 12px;
}

.insuranceStatusItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
  padding: 6px;
  background: white;
  border: 2px solid var(--border-color);
}

.insuranceStatusValue {
  font-weight: bold;
  font-size: 11px;
  padding: 4px 8px;
  border: 2px solid var(--border-color);
  text-align: center;
  min-width: 50px;
}

.applied {
  background-color: var(--success-color);
  color: white;
}

.notApplied {
  background-color: var(--error-color);
  color: white;
}

/* 사용자 정의 수당 스타일 */
.customAllowanceGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.customAllowanceItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px;
  border: 2px solid var(--border-color);
  background: white;
}

.customAllowanceLabelWrapper {
  flex: 1;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.customAllowanceLabelContainer {
  position: relative;
  flex: 1;
}

.customAllowanceLabelInput {
  width: 100%;
  font-size: 12px;
  padding: 4px 6px;
  border: 2px solid var(--border-color);
  background: white;
  font-family: inherit;
}

.customAllowanceLabelInput:focus {
  outline: none;
  border-color: var(--primary-color);
}

.allowanceTaxType {
  font-size: 10px;
  padding: 2px 4px;
  border: 2px solid var(--border-color);
  margin-left: 5px;
  white-space: nowrap;
}

.taxable {
  background-color: var(--error-color);
  color: white;
}

.nonTaxable {
  background-color: var(--success-color);
  color: white;
}

/* 스크롤바 스타일 */
.basicInfoColumn::-webkit-scrollbar {
  width: 16px;
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

.basicInfoColumn::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 3px solid var(--border-color);
}

/* 반응형 스타일 */
@media (max-width: 1400px) {
  .detailsGrid {
    grid-template-columns: 1fr 1fr;
  }
  
  .basicInfoColumn {
    grid-column: span 1;
  }
  
  .additionalAllowanceColumn {
    grid-column: span 2;
  }
}

@media (max-width: 1100px) {
  .detailsGrid {
    grid-template-columns: 1fr;
  }
  
  .basicInfoColumn,
  .allowanceInfoColumn,
  .additionalAllowanceColumn {
    grid-column: span 1;
  }
}

@media (max-width: 768px) {
  .allowanceGrid {
    grid-template-columns: 1fr;
  }
  
  .employeeSettingsGrid,
  .insuranceStatusGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 576px) {
  .customAllowanceItem {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .customAllowanceLabelWrapper {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }
  
  .allowanceInputWrapper {
    width: 100%;
    max-width: none;
  }
} 