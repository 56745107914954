/* 일정 추가 폼 */
.scheduleForm {
  background-color: white;
  border: 3px solid var(--border-color);
  padding: 8px;
  box-shadow: 6px 6px 0 var(--shadow-color);
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
}

.scheduleForm h3 {
  margin: 0 0 12px 0;
  padding-bottom: 8px;
  font-size: 15px;
}

.formRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6px;
  margin-bottom: 6px;
}

.formGroup {
  position: relative;
  margin-bottom: 4px;
}

.formGroup label {
  display: block;
  margin-bottom: 2px;
  color: var(--secondary-color);
  font-size: 13px;
}

.formGroup input {
  width: 100%;
  padding: 4px 6px;
  border: 3px solid var(--border-color);
  background-color: white;
  font-family: inherit;
  font-size: 13px;
  box-shadow: 3px 3px 0 var(--shadow-color);
}

.formGroup input:focus {
  outline: none;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

/* 고객 검색 그룹 */
.customerSearchGroup,
.employeeSearchGroup {
  position: relative;
}

.customerSearchInput,
.employeeSearchInput {
  position: relative;
  z-index: 1;
  display: flex;
  gap: 8px;
}

.customerSearchInput input,
.employeeSearchInput input {
  flex: 1;
}

.btnSearchCustomer,
.btnSearchEmployee,
.btnClearEmployee {
  padding: 8px 12px;
  background-color: var(--primary-color);
  color: white;
  border: 3px solid var(--border-color);
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  box-shadow: 4px 4px 0 var(--shadow-color);
  white-space: nowrap;
}

.btnSearchCustomer:hover,
.btnSearchEmployee:hover,
.btnClearEmployee:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.btnSearchCustomer:active,
.btnSearchEmployee:active,
.btnClearEmployee:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

/* 선택된 직원 표시 */
.selectedEmployee {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 10px;
  background-color: var(--accent-color);
  color: white;
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

/* 폼 액션 버튼 */
.formActions {
  display: flex;
  gap: 10px;
  margin-top: 12px;
}

.scheduleButton {
  padding: 8px 16px;
  font-size: 13px;
}

.scheduleButton.update {
  background-color: var(--warning-color);
}

.scheduleButton.cancel {
  background-color: var(--error-color);
}

.scheduleButton:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.scheduleButton:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

/* required 필드 표시 */
.formGroup label[for="time"]:after {
  content: " *";
  color: var(--error-color);
}

/* 필수 입력 필드 표시 */
.formGroup input:required {
  border-left: 4px solid var(--error-color);
}

/* 드롭다운 메뉴 컨테이너 스타일 */
.dropdownContainer {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  z-index: 1000;
  background: white;
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

/* 드롭다운 항목 스타일 */
.dropdownItem {
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 2px solid var(--border-color);
}

.dropdownItem:last-child {
  border-bottom: none;
}

.dropdownItem:hover {
  background-color: var(--accent-color);
  color: white;
}

/* 더 넓은 필드가 필요한 경우 */
.formGroup.wide {
  grid-column: span 2;
}

/* 전체 너비가 필요한 필드 */
.formGroup.full {
  grid-column: 1 / -1;
}

/* 버튼 스타일 */
.btnAddSchedule,
.btnUpdateSchedule,
.btnCancelEdit {
  padding: 6px 12px;
  border: 3px solid var(--border-color);
  font-family: inherit;
  font-size: 13px;
  cursor: pointer;
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: all 0.2s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
}

button.btnAddSchedule {
  background-color: var(--success-color) !important;
  color: white !important;
}

button.btnUpdateSchedule {
  background-color: var(--warning-color) !important;
  color: white !important;
}

button.btnCancelEdit {
  background-color: var(--error-color) !important;
  color: white !important;
}

.btnAddSchedule:hover,
.btnUpdateSchedule:hover,
.btnCancelEdit:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.btnAddSchedule:active,
.btnUpdateSchedule:active,
.btnCancelEdit:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

/* 버튼 컨테이너 */
.buttonContainer {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 8px;
}