/* 서식 관리 모달 - 레트로 픽셀아트 스타일 */
:root {
  --primary-color: #ff4757;
  --secondary-color: #2f3542;
  --accent-color: #ffa502;
  --background-color: #f1f2f6;
  --border-color: #2f3542;
  --shadow-color: rgba(47, 53, 66, 0.2);
  --success-color: #2ed573;
  --warning-color: #ffa502;
  --error-color: #ff4757;
  
  --doc-primary-color: #ff4757;
  --doc-secondary-color: #2f3542;
  --doc-accent-color: #ffa502;
  --doc-background-color: #f1f2f6;
  --doc-border-color: #2f3542;
  --doc-shadow-color: rgba(47, 53, 66, 0.2);
  --doc-text-color: #000000;
  --doc-item-bg: #ffffff;
}

/* 모달 컨텐츠 스타일 */
.documentsModalContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  image-rendering: pixelated;
  color: var(--doc-text-color);
  overflow: hidden;
}

/* 로딩 컨테이너 스타일 */
.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
}

.loadingContainer .spinner {
  width: 40px;
  height: 40px;
  border: 4px solid var(--background-color);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 문서 컨텐츠 스타일 */
.documentsContent {
  display: flex;
  gap: 20px;
  height: 600px;
  padding: 15px;
  overflow: auto;
}

/* 스크롤바 스타일 */
.documentsContent::-webkit-scrollbar {
  width: 12px;
}

.documentsContent::-webkit-scrollbar-track {
  background: var(--background-color);
}

.documentsContent::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 6px;
  border: 3px solid var(--background-color);
}

.documentsHeader {
  margin-bottom: 20px;
}

.documentsHeader::before {
  content: "서식 관리";
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 20px;
  padding: 10px 0;
  background-color: var(--doc-accent-color);
  width: 100%;
  border: 2px solid #000000;
  letter-spacing: 1px;
}

.partyTypeSelector {
  display: flex;
  gap: 10px;
}

.partyTypeSelector button {
  padding: 10px 20px;
  border: 2px solid #000000;
  background: white;
  cursor: pointer;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  border-radius: 0;
  font-weight: 600;
}

.partyTypeSelector button:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3);
}

.partyTypeSelector button:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
}

.partyTypeSelector button.active {
  background: var(--doc-accent-color);
  color: white;
}

.partyList {
  width: 280px;
  flex-shrink: 0;
  border: 3px solid var(--doc-border-color);
  padding: 10px;
  box-shadow: 4px 4px 0 var(--doc-shadow-color);
  background-color: white;
  height: 570px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.partyList h3 {
  margin-top: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid var(--doc-primary-color);
}

.searchSection {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--doc-secondary-color);
}

.searchBox {
  position: relative;
  margin-bottom: 10px;
}

.searchBox input {
  width: 100%;
  padding: 8px;
  border: 3px solid var(--doc-border-color);
  font-family: inherit;
  font-size: 14px;
  box-shadow: 3px 3px 0 var(--doc-shadow-color);
}

.clearSearch {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: var(--doc-secondary-color);
  line-height: 1;
}

.clearSearch:hover {
  color: var(--doc-primary-color);
}

.categoryFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.categoryCheckbox {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border: 2px solid var(--doc-border-color);
  cursor: pointer;
  font-size: 12px;
  box-shadow: 2px 2px 0 var(--doc-shadow-color);
  background-color: white;
  transition: all 0.2s;
}

.categoryCheckbox:hover {
  transform: translate(-1px, -1px);
  box-shadow: 3px 3px 0 var(--doc-shadow-color);
}

.categoryCheckbox input {
  margin-right: 5px;
}

.listContainer {
  flex: 1;
  overflow-y: auto;
  border: 2px solid var(--doc-border-color);
  padding: 5px;
  background-color: white;
}

.listContainer::-webkit-scrollbar {
  width: 12px;
}

.listContainer::-webkit-scrollbar-track {
  background: var(--doc-background-color);
}

.listContainer::-webkit-scrollbar-thumb {
  background-color: var(--doc-primary-color);
  border-radius: 6px;
  border: 3px solid var(--doc-background-color);
}

.partyItem {
  padding: 10px;
  cursor: pointer;
  border-bottom: 2px solid var(--doc-border-color);
  position: relative;
  transition: all 0.2s ease;
}

.partyItem::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: var(--doc-primary-color);
  transition: all 0.2s ease;
  opacity: 0;
}

.partyItem:hover {
  background: var(--doc-background-color);
  transform: translate(-2px, 0);
}

.partyItem:hover::before {
  opacity: 1;
  width: 6px;
}

.partyItem.selected {
  background: var(--doc-background-color);
  transform: translate(-2px, 0);
  font-weight: bold;
}

.partyItem.selected::before {
  opacity: 1;
  width: 6px;
}

.partyItemContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.partyName {
  font-weight: bold;
}

.partyContact {
  font-size: 12px;
  color: var(--doc-secondary-color);
}

.categoryTag {
  display: inline-block;
  padding: 2px 6px;
  font-size: 11px;
  border: 2px solid var(--doc-border-color);
  margin-top: 4px;
  box-shadow: 2px 2px 0 var(--doc-shadow-color);
  background-color: var(--doc-accent-color);
  color: white;
  max-width: fit-content;
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--doc-secondary-color);
  font-size: 14px;
  text-align: center;
  padding: 20px;
}

.contractSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: hidden;
  height: 100%;
}

.contractTypeSelector {
  margin-bottom: 10px;
}

.contractTypeSelector select {
  width: 100%;
  padding: 10px;
  border: 3px solid var(--doc-border-color);
  font-family: inherit;
  background-color: white;
  box-shadow: 4px 4px 0 var(--doc-shadow-color);
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.contractHistory {
  border: 3px solid var(--doc-border-color);
  padding: 10px;
  height: 200px;
  overflow-y: auto;
  box-shadow: 4px 4px 0 var(--doc-shadow-color);
  background-color: white;
}

.historyList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contractItem {
  border: 2px solid var(--doc-border-color);
  padding: 10px;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 3px 3px 0 var(--doc-shadow-color);
}

.contractItem:hover {
  transform: translate(-2px, -2px);
  box-shadow: 5px 5px 0 var(--doc-shadow-color);
  background-color: var(--doc-background-color);
}

.contractItem.selected {
  background-color: var(--doc-accent-color);
  color: white;
  transform: translate(2px, 2px);
  box-shadow: 1px 1px 0 var(--doc-shadow-color);
}

.contractDate {
  font-size: 12px;
  margin-bottom: 4px;
  color: var(--doc-secondary-color);
}

.contractItem.selected .contractDate {
  color: white;
}

.contractTitle {
  font-weight: bold;
}

.noContracts {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--doc-secondary-color);
  font-style: italic;
}

.contractForm {
  flex: 1;
  border: 3px solid var(--doc-border-color);
  padding: 15px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 4px 4px 0 var(--doc-shadow-color);
}

.contractForm::-webkit-scrollbar {
  width: 12px;
}

.contractForm::-webkit-scrollbar-track {
  background: var(--doc-background-color);
}

.contractForm::-webkit-scrollbar-thumb {
  background-color: var(--doc-primary-color);
  border-radius: 6px;
  border: 3px solid var(--doc-background-color);
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .documentsContent {
    flex-direction: column;
  }
  
  .partyList {
    width: 100%;
    height: auto;
    max-height: 300px;
  }
  
  .listContainer {
    max-height: 200px;
  }
  
  .contractHistory {
    height: 150px;
  }
} 