/* 레트로 픽셀아트 스타일 - 계약서 컴포넌트 */
.contract-container {
  --contract-primary-color: #000000;
  --contract-secondary-color: #d1d8e0;
  --contract-accent-color: #ff4757;
  --contract-background-color: #ffffff;
  --contract-border-color: #2f3542;
  --contract-shadow-color: rgba(0, 0, 0, 0.25);
  
  padding: 20px;
  background: white;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  image-rendering: pixelated;
  border: 2px solid #000000;
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3);
}

.contract-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: bold;
  background-color: var(--contract-accent-color);
  color: white;
  padding: 10px;
  border: 2px solid #000000;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.contract-header {
  margin-bottom: 30px;
  padding: 15px;
  background: #f8f8f8;
  border-radius: 0;
  border: 2px solid #000000;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.contract-section {
  margin-bottom: 25px;
  padding: 15px;
  border: 2px solid #000000;
  border-radius: 0;
  background-color: white;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
  position: relative;
}

.contract-section::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: var(--contract-accent-color);
}

.contract-section h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #000000;
  border-bottom: 2px solid #000000;
  padding-bottom: 5px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #000000;
  font-weight: bold;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 2px solid #000000;
  border-radius: 0;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.2);
}

.form-group select {
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 30px;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  outline: none;
  border-color: var(--contract-accent-color);
  box-shadow: 3px 3px 0 rgba(255, 71, 87, 0.3);
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.contract-actions {
  margin-top: 30px;
  text-align: center;
}

.contract-actions button {
  padding: 10px 20px;
  background: var(--contract-accent-color);
  color: white;
  border: 2px solid #000000;
  border-radius: 0;
  cursor: pointer;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease;
}

.contract-actions button:disabled {
  background: #ccc;
  cursor: not-allowed;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.contract-actions button:hover:not(:disabled) {
  background: #ff4444;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3);
}

.contract-actions button:active:not(:disabled) {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
}

/* 서명란 스타일 */
.signature-section {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 2px solid #000000;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.signature-box {
  width: 45%;
  text-align: center;
  padding: 15px;
  border: 2px dashed #000000;
}

.signature-line {
  margin-top: 50px;
  border-top: 2px solid #000;
  padding-top: 10px;
}

/* 날짜 선택기 커스텀 스타일 */
input[type="date"] {
  padding: 8px;
  border: 2px solid #000000;
  border-radius: 0;
  width: 100%;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.2);
}

input[type="date"]:focus {
  outline: none;
  border-color: var(--contract-accent-color);
  box-shadow: 3px 3px 0 rgba(255, 71, 87, 0.3);
} 