/* 근로자 선택 관련 스타일 */
.employeeSearchGroup {
  position: relative;
  margin-bottom: 15px;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
}

.employeeSearchInput {
  display: flex;
  gap: 10px;
}

.employeeSearchInput input {
  flex: 1;
  cursor: pointer;
  background-color: white;
  border: 3px solid var(--border-color);
  padding: 8px 10px;
  font-family: inherit;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.employeeSearchInput input:focus {
  outline: none;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.selectedEmployee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: var(--accent-color);
  color: white;
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
  width: 100%;
}

.btnClearEmployee {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 0 5px;
  font-family: inherit;
}

.btnClearEmployee:hover {
  color: var(--error-color);
}

.btnSearchEmployee {
  background-color: var(--primary-color);
  color: white;
  border: 3px solid var(--border-color);
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  font-family: inherit;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.btnSearchEmployee:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.btnSearchEmployee:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.employeeSearchDropdown {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background-color: white;
  border: 3px solid var(--border-color);
  box-shadow: 6px 6px 0 var(--shadow-color);
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;
}

.employeeSearchHeader {
  padding: 12px;
  border-bottom: 3px solid var(--border-color);
  background-color: var(--background-color);
}

.employeeSearchHeader input {
  width: 100%;
  padding: 8px 10px;
  border: 3px solid var(--border-color);
  font-family: inherit;
  font-size: 14px;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.employeeSearchHeader input:focus {
  outline: none;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.employeeSearchResults {
  max-height: 250px;
  overflow-y: auto;
}

.employeeItem {
  padding: 12px;
  border-bottom: 3px solid var(--border-color);
  cursor: pointer;
  transition: all 0.2s;
  background-color: white;
}

.employeeItem:hover {
  background-color: var(--accent-color);
  color: white;
  transform: translate(-2px, -2px);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.employeeItem:last-child {
  border-bottom: none;
}

.employeeName {
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--secondary-color);
}

.employeeItem:hover .employeeName {
  color: white;
}

.employeePosition {
  font-size: 14px;
  color: var(--secondary-color);
}

.employeeItem:hover .employeePosition {
  color: white;
}

.noEmployees {
  padding: 20px;
  text-align: center;
  color: var(--secondary-color);
  background-color: var(--background-color);
  border-top: 3px solid var(--border-color);
}

/* 스크롤바 스타일 */
.employeeSearchDropdown::-webkit-scrollbar,
.employeeSearchResults::-webkit-scrollbar {
  width: 16px;
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

.employeeSearchDropdown::-webkit-scrollbar-thumb,
.employeeSearchResults::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 3px solid var(--border-color);
}

.employeeSearchDropdown::-webkit-scrollbar-track,
.employeeSearchResults::-webkit-scrollbar-track {
  background: var(--background-color);
  border: 3px solid var(--border-color);
} 