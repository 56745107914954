/* 소상공인 지원 프로모션 */
.bizPromotionContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Noto Sans KR', sans-serif;
}

.bizPromotionTitle {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
}

.bizPromotionTitle:after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: #4a6cf7;
  margin: 1rem auto;
}

/* 정부 지원 사이트 섹션 */
.govSupportSection {
  margin-bottom: 3rem;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.sectionTitle {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 1rem;
}

.sectionTitle:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 24px;
  background-color: #4a6cf7;
  border-radius: 2px;
}

.govSupportLinks {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
}

.govSupportLink {
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease;
}

.govSupportLink:hover {
  transform: translateY(-5px);
}

.govSupportCard {
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: box-shadow 0.3s ease;
}

.govSupportCard:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.govSupportCard h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.govSupportCard p {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.visitButton {
  display: inline-block;
  background-color: #4a6cf7;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  align-self: flex-start;
}

/* 유망 상품 섹션 */
.featuredProductsSection {
  margin-bottom: 3rem;
}

.filterSection {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
}

.filterItem {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.filterItem label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #555;
}

.filterItem select,
.filterItem input {
  padding: 0.6rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  min-width: 200px;
}

.filterButton {
  background-color: #4a6cf7;
  color: white;
  border: none;
  padding: 0.6rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-end;
}

.filterButton:hover {
  background-color: #3a5ce5;
}

.loading,
.errorMessage,
.initialMessage,
.noProducts {
  text-align: center;
  padding: 2rem;
  font-size: 1.1rem;
  color: #666;
}

.errorMessage {
  color: #e74c3c;
}

.productsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.productCard {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.productCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.productHeader {
  background-color: #4a6cf7;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productHeader h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
}

.provider {
  font-size: 0.8rem;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
}

.productDetails {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  border-bottom: 1px solid #eee;
}

.detailItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.label {
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 0.2rem;
}

.value {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}

.productBenefits {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.productBenefits h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333;
}

.productBenefits ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.productBenefits li {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 0.3rem;
  padding-left: 1.2rem;
  position: relative;
}

.productBenefits li:before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #4a6cf7;
}

.productPrice {
  padding: 1rem;
  text-align: center;
  background-color: #f8f9fa;
}

.priceValue {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
}

.priceUnit {
  font-size: 0.9rem;
  color: #666;
  margin-left: 0.2rem;
}

.applyButton {
  width: 100%;
  background-color: #4a6cf7;
  color: white;
  border: none;
  padding: 0.8rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.applyButton:hover {
  background-color: #3a5ce5;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .bizPromotionContent {
    padding: 1rem;
  }
  
  .bizPromotionTitle {
    font-size: 2rem;
  }
  
  .govSupportLinks {
    grid-template-columns: 1fr;
  }
  
  .filterSection {
    flex-direction: column;
  }
  
  .filterItem select,
  .filterItem input {
    width: 100%;
  }
  
  .filterButton {
    width: 100%;
    align-self: center;
  }
  
  .productsContainer {
    grid-template-columns: 1fr;
  }
}