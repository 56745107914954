/* 레트로 픽셀 아트 테마로 변경된 통계 섹션 스타일 */
.statisticsContainer {
  --stats-primary-color: #000000;
  --stats-secondary-color: #d1d8e0;
  --stats-accent-color: #ff4757;
  --stats-background-color: #ffffff;
  --stats-border-color: #2f3542;
  --stats-shadow-color: rgba(0, 0, 0, 0.25);
  --stats-text-color: #000000;
  --stats-card-bg: #ffffff;
  
  padding: 5px;
  height: 98%;
  overflow-y: auto;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  image-rendering: pixelated;
  color: var(--stats-text-color);
}

.statsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  background-color: var(--stats-accent-color);
  padding: 4px 10px;
  border: 2px solid #000000;
}

.statsHeader h2 {
  margin: 0;
  font-size: 1.2rem;
  color: #ffffff;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.5);
  font-weight: bold;
  letter-spacing: 1px;
}

.statsDate {
  font-size: 0.85rem;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 2px 6px;
  border: 1px solid #ffffff;
  font-weight: bold;
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.statsCard {
  background: var(--stats-card-bg);
  border: 2px solid #000000;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
  padding: 2px;
  position: relative;
}

.statsCard h3 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 0.9rem;
  color: #ffffff;
  background-color: var(--stats-primary-color);
  padding: 4px 8px;
  letter-spacing: 0.5px;
  text-align: center;
  border: none;
}

/* 비즈니스 요약 카드 */
.summaryGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.summaryItem {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  padding: 5px;
  border: 1px solid #000000;
}

.summaryLabel {
  font-size: 0.8rem;
  color: #333;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: center;
}

.summaryValue {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--stats-primary-color);
  text-align: center;
  padding: 4px;
  background-color: #ffffff;
  border: 1px dashed #000000;
}

.positive {
  color: #28a745;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.negative {
  color: #dc3545;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
}

/* 차트 스타일 */
.chartContainer {
  height: 140px;
  width: 100%;
  margin-top: 5px;
  position: relative;
  padding: 8px;
  background-color: #f8f8f8;
  border: 1px solid #000000;
}

.barChart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 110px;
  width: 100%;
  border-bottom: 2px solid #000000;
  padding-bottom: 5px;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.05) 1px, transparent 1px);
  background-size: 100% 20px;
}

.barItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  flex: 1;
}

.bar {
  width: 25px;
  background: #4e73df;
  border: 2px solid #000000;
  position: relative;
  transition: height 0.3s ease;
  min-height: 5px;
  image-rendering: pixelated;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.barValue {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.7rem;
  color: #000000;
  white-space: nowrap;
  background-color: #ffffff;
  padding: 2px 4px;
  border: 1px solid #000000;
}

.barLabel {
  margin-top: 5px;
  font-size: 0.8rem;
  color: #000000;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  font-weight: bold;
}

/* 제품 리스트 */
.productList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.productItem {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  padding: 8px;
  border: 1px solid #000000;
}

.productInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.productName {
  font-size: 0.9rem;
  color: #000000;
  font-weight: bold;
}

.productSales {
  font-size: 0.9rem;
  color: #000000;
  font-weight: bold;
}

.productBarContainer {
  height: 10px;
  background-color: #ffffff;
  border: 1px solid #000000;
  position: relative;
}

.productBar {
  height: 100%;
  background: #36b9cc;
  border-right: 1px solid #000000;
}

.productPercentage {
  position: absolute;
  right: 5px;
  top: -18px;
  font-size: 0.75rem;
  color: #000000;
  background-color: #ffffff;
  padding: 1px 3px;
  border: 1px solid #000000;
}

/* 세그먼트 차트 */
.segmentContainer {
  padding: 10px 0;
}

.segmentChart {
  height: 20px;
  border: 1px solid #000000;
  overflow: hidden;
  display: flex;
}

.segmentSlice {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s ease;
  border-right: 1px solid #000000;
}

.segmentLabel {
  font-size: 0.8rem;
  color: white;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  font-weight: bold;
}

/* 직원 테이블 */
.employeeTable {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #000000;
}

.employeeTable th,
.employeeTable td {
  padding: 8px 12px;
  text-align: left;
  border: 1px solid #000000;
}

.employeeTable th {
  font-weight: 600;
  color: #ffffff;
  background-color: #000000;
}

.employeeTable tr:nth-child(even) {
  background-color: #f0f0f0;
}

/* 로딩 및 에러 상태 */
.statsLoading,
.statsError,
.statsEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #000000;
  font-size: 1rem;
  padding: 20px;
  border: 2px solid #000000;
  background-color: #f8f8f8;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.statsError {
  color: #dc3545;
  border-color: #dc3545;
}

/* 월별 재무 현황 그래프 스타일 */
.barGroup {
  display: flex;
  gap: 4px;
}

.revenueBar {
  background-color: #4e73df;
  border: 1px solid #000000;
}

.expenseBar {
  background-color: #e74a3b;
  border: 1px solid #000000;
}

/* 스크롤바 스타일링 */
.statisticsContainer::-webkit-scrollbar {
  width: 10px;
  background-color: #e6e6e6;
  border-left: 2px solid #000000;
}

.statisticsContainer::-webkit-scrollbar-thumb {
  background-color: #454545;
  border: 2px solid #e6e6e6;
}

/* 스크롤바 버튼 */
.statisticsContainer::-webkit-scrollbar-button {
  background-color: #e6e6e6;
  height: 10px;
  border: 1px solid #000000;
}

.statisticsContainer::-webkit-scrollbar-button:vertical:start {
  background-image: linear-gradient(to top, #000000 40%, transparent 40%);
  background-size: 6px 6px;
  background-repeat: no-repeat;
  background-position: center;
}

.statisticsContainer::-webkit-scrollbar-button:vertical:end {
  background-image: linear-gradient(to bottom, #000000 40%, transparent 40%);
  background-size: 6px 6px;
  background-repeat: no-repeat;
  background-position: center;
}

/* 추가적인 레트로 느낌 스타일링 */
.statsCard::after {
  content: "";
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  background-color: #000000;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}

/* 추가적인 픽셀아트 느낌의 그래프 스타일 */
.bar::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 4px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
}

.salaryList {
  border: 2px solid #000000;
  padding: 8px;
  background-color: #f8f8f8;
}

.salaryTotal {
  font-weight: bold;
  background-color: #e6e6e6;
  padding: 5px 8px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #000000;
  margin-top: 10px;
}

.salaryTotalAmount {
  color: #e74a3b;
  font-weight: bold;
}

.salaryBarContainer {
  height: 10px;
  background-color: #ffffff;
  border: 1px solid #000000;
  margin-top: 5px;
  position: relative;
}

.salaryBar {
  height: 100%;
  background-color: #e74a3b;
  border-right: 1px solid #000000;
}

.expenseChart {
  margin-top: 10px;
  border: 1px solid #000000;
  padding: 8px;
  background-color: #f8f8f8;
}

.expenseItem {
  margin-bottom: 4px;
  border-bottom: 1px dashed #000000;
}

.expenseInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;
}

.expenseMonth {
  font-weight: bold;
  color: #000000;
}

.expenseBars {
  display: flex;
  height: 20px;
  background-color: #ffffff;
  border: 1px solid #000000;
}

.expenseBar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.laborCost {
  background-color: #4e73df;
  border-right: 1px solid #000000;
}

.otherCost {
  background-color: #e74a3b;
}

.expenseValue {
  font-size: 0.75rem;
  color: #ffffff;
  text-shadow: 1px 1px 0 #000000;
} 