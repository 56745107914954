.footer {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 20px 0;
  width: 100%;
  margin-top: auto;
}

.footerContent {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.companyInfo, .footerLinks {
  margin: 20px;
  text-align: left;
}

.companyInfo h3, .footerLinks h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #ddd;
  text-align: left;
}

.companyInfo p {
  margin: 5px 0;
  font-size: 14px;
  color: #aaa;
  text-align: left;
}

.footerLinks ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.footerLinks li {
  margin-bottom: 8px;
}

.footerLinks a {
  color: #aaa;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
  display: block;
}

.footerLinks a:hover {
  color: #fff;
}

.copyright {
  text-align: center;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #333;
  font-size: 12px;
  color: #888;
}

@media (max-width: 768px) {
  .footerContent {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 30px;
  }
  
  .companyInfo, .footerLinks {
    width: 100%;
    margin: 10px 0;
  }

  .copyright {
    padding: 20px 30px 0;
  }
}