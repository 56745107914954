.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.formGroup {
  margin-bottom: 15px;
  width: 100%;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.formInput {
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.loginButton {
  width: 120px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #20B2AA;
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.loginButton:hover {
  background-color: #1E90FF;
}

.demoButton {
  width: 120px;
  margin-top: 10px;
  padding: 10px 20px;
  border: 1px solid #20B2AA;
  border-radius: 5px;
  background-color: white;
  color: #20B2AA;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.demoButton:hover {
  background-color: #F0F8FF;
  color: #1E90FF;
  border-color: #1E90FF;
}