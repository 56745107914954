/* 북마크 - 레트로 픽셀아트 스타일 */
.diaryBookmark {
  --bookmark-primary-color: #000000;
  --bookmark-secondary-color: #d1d8e0;
  --bookmark-accent-color: #ff4757;
  --bookmark-shadow-color: rgba(0, 0, 0, 0.25);
  
  width: 45px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 70px;
  border-left: 2px solid #000000;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  image-rendering: pixelated;
}

.bookmarkItem {
  position: relative;
  height: 90px;
  background-color: white;
  right: -10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: all 0.2s ease;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.2);
  gap: 5px;
  border: 2px solid #000000;
  border-right: none;
  font-weight: bold;
}

.bookmarkItem span {
  writing-mode: vertical-lr;
  color: #000000;
  font-weight: bold;
  padding: 5px 0;
  font-size: 13px;
}

.bookmarkIcon {
  width: 20px;
  height: 20px;
  background-color: var(--bookmark-accent-color);
  border: 1px solid #000000;
}

.bookmarkIcon.calendarIcon {
  background-image: none;
}

.bookmarkIcon.photoIcon {
  background-image: none;
}

.bookmarkItem.active {
  background-color: var(--bookmark-accent-color);
  right: 0;
  box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.3);
}

.bookmarkItem.active span {
  color: white;
}

.bookmarkItem:hover {
  transform: translateX(-3px);
  background-color: #ffeef0;
}

.bookmarkItem.active:hover {
  background-color: var(--bookmark-accent-color);
}

/* 반응형 디자인 - 북마크 */
@media (max-width: 768px) {
  .diaryBookmark {
    width: 100%;
    height: 60px;
    flex-direction: row;
    padding: 0;
    justify-content: center;
    gap: 20px;
    border-left: none;
    border-bottom: 2px solid #000000;
  }
  
  .bookmarkItem {
    flex-direction: row;
    width: 120px;
    height: 40px;
    right: 0;
    bottom: -10px;
    border-radius: 0;
    gap: 8px;
    border: 2px solid #000000;
    border-bottom: none;
  }
  
  .bookmarkItem span {
    writing-mode: horizontal-tb;
  }
  
  .bookmarkItem.active {
    bottom: 0;
    transform: translateY(-5px);
  }
}

/* 화면 높이가 작을 때 조정 */
@media (max-height: 750px) {
  .bookmarkItem {
    height: 70px;
  }
} 