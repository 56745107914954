/* 기본 색상 변수 정의 */
:root {
  --primary-color: #ff4757;
  --secondary-color: #2f3542;
  --accent-color: #ffa502;
  --background-color: #f1f2f6;
  --border-color: #2f3542;
  --shadow-color: rgba(47, 53, 66, 0.2);
  --success-color: #2ed573;
  --warning-color: #ffa502;
  --error-color: #ff4757;
}

/* 일정 관리 컨테이너 */
.scheduleModalContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
  image-rendering: pixelated;
  box-sizing: border-box;
}

/* 일정 콘텐츠 영역 */
.scheduleContent {
  overflow-x: auto;
  height: 100%;
  padding: 15px;
}

/* 로딩 컨테이너 스타일 */
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 18px;
  color: var(--secondary-color);
  background-color: rgba(255, 255, 255, 0.7);
}

/* 스크롤바 스타일 */
.scheduleContent::-webkit-scrollbar {
  width: 16px;
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

.scheduleContent::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 3px solid var(--border-color);
}

.scheduleContent::-webkit-scrollbar-track {
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

/* 일정 콘텐츠 레이아웃 수정 */
.scheduleLayout {
  display: flex;
  gap: 20px;
  height: 100%;
}

/* 캘린더 영역 */
.calendarPanel {
  flex: 0 0 300px; /* 고정 너비 */
  background-color: white;
  border: 3px solid var(--border-color);
  padding: 12px;
  box-shadow: 6px 6px 0 var(--shadow-color);
}

/* 우측 영역 (예약된 일정 + 폼) */
.scheduleDetailsPanel {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 100%;
}

/* 예약된 일정 목록 */
.scheduleList {
  flex: 0 0 auto; /* 내용물 높이만큼만 차지 */
  background-color: white;
  border: 3px solid var(--border-color);
  padding: 12px;
  box-shadow: 6px 6px 0 var(--shadow-color);
  max-height: 200px;
  overflow-y: auto;
}

.scheduleList h3 {
  margin: 0 0 10px 0;
  padding-bottom: 8px;
}

/* 스크롤바 스타일 */
.scheduleList::-webkit-scrollbar {
  width: 16px;
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

.scheduleList::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 3px solid var(--border-color);
}

/* 일정 카드 스타일 개선 */
.scheduleCard {
  display: grid;
  grid-template-columns: 100px minmax(200px, 1fr) 120px 100px; /* 시간, 내용, 금액, 버튼 영역 */
  width: 100%;
  gap: 12px;
  align-items: center;
  padding: 8px 12px;
  margin-bottom: 8px;
  border: 3px solid var(--border-color);
  background: white;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.scheduleTime {
  font-size: 13px;
  white-space: nowrap;
  min-width: 90px;
}

.scheduleInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 0; /* 텍스트 오버플로우 방지 */
}

.scheduleName {
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scheduleContact {
  color: var(--secondary-color);
  margin: 0;
  font-size: 12px;
}

.scheduleAmount {
  text-align: right;
  color: var(--accent-color);
  font-weight: bold;
  white-space: nowrap;
}

.scheduleActions {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  white-space: nowrap;
}

/* 일정 폼 */
.scheduleForm {
  flex: 1; /* 남은 공간 차지 */
  background-color: white;
  border: 3px solid var(--border-color);
  padding: 12px;
  box-shadow: 6px 6px 0 var(--shadow-color);
  overflow-y: auto;
}

/* 폼 레이아웃 개선 */
.formRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4열 그리드 */
  gap: 10px;
  margin-bottom: 10px;
}

.formGroup {
  margin-bottom: 8px;
}

.formGroup label {
  margin-bottom: 4px;
  font-size: 13px;
}

.formGroup input {
  padding: 6px 8px;
  font-size: 13px;
}

/* 버튼 스타일 통일 */
.btnPrimary,
.btnEditSchedule,
.btnDeleteSchedule,
.btnAddSchedule,
.btnUpdateSchedule,
.btnCancelEdit {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: 3px solid var(--border-color);
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
  font-family: inherit;
  box-shadow: 4px 4px 0 var(--shadow-color);
  text-transform: uppercase;
}

.btnPrimary:hover,
.btnEditSchedule:hover,
.btnDeleteSchedule:hover,
.btnAddSchedule:hover,
.btnUpdateSchedule:hover,
.btnCancelEdit:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.btnPrimary:active,
.btnEditSchedule:active,
.btnDeleteSchedule:active,
.btnAddSchedule:active,
.btnUpdateSchedule:active,
.btnCancelEdit:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

/* 검색 드롭다운 스타일 */
.customerSearchDropdown,
.employeeSearchDropdown {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background-color: white;
  border: 3px solid var(--border-color);
  box-shadow: 6px 6px 0 var(--shadow-color);
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;
}

/* 검색 드롭다운 스크롤바 스타일 */
.customerSearchDropdown::-webkit-scrollbar,
.employeeSearchDropdown::-webkit-scrollbar {
  width: 16px;
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

.customerSearchDropdown::-webkit-scrollbar-thumb,
.employeeSearchDropdown::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 3px solid var(--border-color);
}

.searchHeader {
  padding: 12px;
  border-bottom: 3px solid var(--border-color);
  background-color: var(--background-color);
}

.searchHeader input {
  width: 100%;
  padding: 8px 10px;
  border: 3px solid var(--border-color);
  font-family: inherit;
  font-size: 14px;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

/* 검색 결과 아이템 */
.customerItem,
.employeeItem {
  padding: 12px;
  border-bottom: 3px solid var(--border-color);
  cursor: pointer;
  transition: background-color 0.2s;
}

.customerItem:hover,
.employeeItem:hover {
  background-color: var(--accent-color);
  color: white;
}

/* 반응형 스타일 */
@media (max-width: 1024px) {
  .scheduleLayout {
    flex-direction: column;
  }

  .calendarPanel {
    flex: none;
    width: 100%;
  }

  .scheduleDetailsPanel {
    width: 100%;
  }

  .scheduleCard {
    grid-template-columns: 90px 1fr auto;
  }
  
  .scheduleAmount {
    grid-column: 2;
    text-align: left;
  }
  
  .formRow {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .scheduleCard {
    grid-template-columns: 90px 1fr 80px;
  }
  
  .scheduleActions {
    grid-column: 1 / -1;
    justify-content: flex-start;
  }
  
  .formRow {
    grid-template-columns: 1fr;
  }
}

/* 배지 스타일 */
.employeeBadge {
  display: inline-block;
  padding: 4px 8px;
  background-color: var(--accent-color);
  color: white;
  border: 2px solid var(--border-color);
  box-shadow: 2px 2px 0 var(--shadow-color);
  font-size: 12px;
}

/* 선택된 직원 표시 */
.selectedEmployee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: var(--accent-color);
  color: white;
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

/* 달력 스타일 */
.hasSchedule {
  background-color: var(--accent-color) !important;
  color: white !important;
  border: 2px solid var(--border-color) !important;
  box-shadow: 2px 2px 0 var(--shadow-color) !important;
}

.hasSchedule:hover {
  transform: translate(-1px, -1px);
  box-shadow: 3px 3px 0 var(--shadow-color) !important;
}

.reactCalendar__tile--active.hasSchedule {
  background: #ff9999;
}

/* 고객 검색 관련 스타일 */
.customerSearchGroup {
  position: relative;
}

.customerSearchInput {
  display: flex;
  gap: 10px;
}

.customerSearchInput input {
  flex: 1;
}

.btnSearchCustomer {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 11px;
  cursor: pointer;
  white-space: nowrap;
  border: 4px solid var(--border-color);
  padding: 8px 16px;
  font-family: 'DungGeunMo', monospace;
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: all 0.2s;
  background-color: var(--primary-color);
  color: white;
}

.customerSearchDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  z-index: 10;
  max-height: 250px;
  overflow-y: auto;
  border: 4px solid var(--border-color);
  background: white;
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.customerSearchHeader {
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.customerSearchHeader input {
  width: 100%;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
}

.customerSearchResults {
  max-height: 250px;
  overflow-y: auto;
}

.customerItem {
  padding: 8px 12px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s;
  border-bottom: 4px solid var(--border-color);
  padding: 8px;
  transition: all 0.2s;
}

.customerItem:hover {
  background-color: var(--accent-color);
  color: white;
}

.customerName {
  font-weight: bold;
  margin-bottom: 3px;
}

.customerContact {
  font-size: 12px;
  color: #666;
}

.noCustomers {
  padding: 20px;
  text-align: center;
  color: #999;
}

/* 아이콘 스타일 (FontAwesome 또는 유니코드 아이콘 사용) */
.iconEdit:before {
  content: "✎"; /* 연필 유니코드 아이콘 */
  margin-right: 4px;
}

.iconDelete:before {
  content: "×"; /* 엑스 유니코드 아이콘 */
  margin-right: 4px;
  font-size: 14px;
}

/* required 필드 표시 */
.formGroup label[for="time"]:after {
  content: " *";
  color: #ff3333;
}

/* 필수 입력 필드 표시 */
input:required {
  border-left: 3px solid #ff3333;
}

/* 근로자 선택 관련 스타일 */
.employeeSearchGroup {
  position: relative;
  margin-bottom: 10px;
}

.employeeSearchInput {
  display: flex;
  gap: 10px;
}

.employeeSearchInput input {
  flex: 1;
  cursor: pointer;
  background-color: #f9f9f9;
}

.btnClearEmployee {
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  font-size: 14px;
  padding: 0 5px;
  border: 2px solid var(--border-color);
  background: var(--primary-color);
  color: white;
  padding: 4px 8px;
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.btnClearEmployee:hover {
  color: #f44336;
}

.btnSearchEmployee {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 11px;
  cursor: pointer;
  white-space: nowrap;
  border: 4px solid var(--border-color);
  padding: 8px 16px;
  font-family: 'DungGeunMo', monospace;
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: all 0.2s;
  background-color: var(--primary-color);
  color: white;
}

.employeeSearchDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  z-index: 10;
  max-height: 250px;
  overflow-y: auto;
  border: 4px solid var(--border-color);
  background: white;
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.employeeSearchHeader {
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.employeeSearchResults {
  max-height: 200px;
  overflow-y: auto;
}

.employeeItem {
  padding: 8px 12px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s;
  border-bottom: 4px solid var(--border-color);
  padding: 8px;
  transition: all 0.2s;
}

.employeeItem:hover {
  background-color: var(--accent-color);
  color: white;
}

.employeeName {
  font-weight: bold;
  margin-bottom: 3px;
}

.employeePosition {
  font-size: 12px;
  color: #666;
}

.noEmployees {
  padding: 20px;
  text-align: center;
  color: #999;
}

/* 일정 카드에 근로자 표시 */
.scheduleEmployee {
  display: flex;
  margin-bottom: 4px;
}

.employeeBadge {
  display: inline-block;
  padding: 2px 6px;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  color: #1890ff;
  border-radius: 4px;
  font-size: 12px;
  border: 2px solid var(--border-color);
  background: var(--accent-color);
  color: white;
  padding: 4px 8px;
  font-size: 12px;
  image-rendering: pixelated;
} 