/* 출결 체크 페이지 스타일 */
.attendanceCheckContainer {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 0;
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
}

.attendanceCheckContainer h2 {
  text-align: center;
  margin-bottom: 20px;
  color: var(--secondary-color);
  border-bottom: 4px solid var(--primary-color);
  padding-bottom: 10px;
}

.attendanceCheckContent {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.errorContainer,
.successContainer {
  text-align: center;
  padding: 20px;
  color: var(--secondary-color);
}

.errorIcon,
.successIcon {
  font-size: 48px;
  margin-bottom: 20px;
}

.loading {
  padding: 20px;
  text-align: center;
  color: var(--secondary-color);
}

.backButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--error-color);
  color: white;
  border: 3px solid var(--border-color);
  border-radius: 0;
  cursor: pointer;
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: all 0.2s;
}

.backButton:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.backButton:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.confirmContainer {
  text-align: center;
  padding: 20px;
}

.confirmButtons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.confirmButton {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: 3px solid var(--border-color);
  border-radius: 0;
  cursor: pointer;
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: all 0.2s;
}

.confirmButton:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.confirmButton:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.cancelButton {
  padding: 10px 20px;
  background-color: white;
  border: 3px solid var(--border-color);
  border-radius: 0;
  cursor: pointer;
  box-shadow: 4px 4px 0 var(--shadow-color);
  transition: all 0.2s;
}

.cancelButton:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.cancelButton:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.errorDetails {
  margin-top: 15px;
  padding: 10px;
  background-color: white;
  border-radius: 0;
  text-align: left;
  font-size: 12px;
  color: var(--secondary-color);
  color: #666;
}

.errorActions {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.retryButton {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* 출결 유형 선택기 스타일 */
.attendanceTypeSelector {
  margin: 15px 0;
  width: 100%;
  max-width: 300px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.attendanceTypeSelector label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
  font-size: 14px;
}

.attendanceTypeSelector select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 14px;
  cursor: pointer;
}

.attendanceTypeSelector select:focus {
  border-color: #ff3333;
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 51, 51, 0.2);
}

/* 출결 유형별 배경색만 적용 (글자색 제거) */
.attendanceTypeSelector select option[value="출근"] {
  background-color: #e6ffe6;
}

.attendanceTypeSelector select option[value="퇴근"] {
  background-color: #e6f7ff;
}

.attendanceTypeSelector select option[value="연차"] {
  background-color: #fff7e6;
}

.attendanceTypeSelector select option[value="반차"] {
  background-color: #fcf2e8;
}

.attendanceTypeSelector select option[value="외출"] {
  background-color: #f9f0ff;
}

.attendanceTypeSelector select option[value="조퇴"] {
  background-color: #fff1f0;
}

/* 모바일 대응 */
@media (max-width: 480px) {
  .attendanceTypeSelector {
    max-width: 100%;
  }
  
  .errorActions {
    flex-direction: column;
  }
} 