/* Interior3DCube.css */

/* 컨테이너: 전체 방 영역 */
.roomWrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  margin: auto;
}

/* 메인 벽 */
.mainWall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--wall-bg, #FADCD9);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* 창문 */
.window {
  width: 350px;
  height: 200px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
}

/* 가구 아이템 기본 스타일 */
.furnitureItem {
  position: absolute;
  image-rendering: pixelated;
}

/* 간판 스타일 */
.cubeSigns {
  position: absolute;
  top: 20px;
  left: 50%;
  text-align: center;
  z-index: 10;
  pointer-events: none;
  transform: translateX(-50%);
}

.bannerContainer {
  position: relative;
  display: inline-block;
}

.bannerImage {
  width: 300px; /* 배너 이미지 크기 조정 */
  height: auto;
}

.businessSign {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: #2c3e50;
  white-space: nowrap;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  letter-spacing: 2px;
}

.ownerSign {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  color: #4a4a4a;
  white-space: nowrap;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  letter-spacing: 1px;
}

/* 애니메이션 정의 */
@keyframes neonPulse {
  0% { opacity: 1; }
  50% { opacity: 0.8; }
  100% { opacity: 1; }
}

@keyframes borderGlow {
  0% { filter: blur(10px) hue-rotate(0deg); }
  100% { filter: blur(10px) hue-rotate(360deg); }
}

@keyframes stencilFade {
  0% { opacity: 0.85; }
  50% { opacity: 1; }
  100% { opacity: 0.85; }
}

@keyframes softPulse {
  0% { opacity: 0.9; }
  50% { opacity: 1; }
  100% { opacity: 0.9; }
}

@keyframes softGlow {
  0% { filter: blur(8px) hue-rotate(0deg); }
  100% { filter: blur(8px) hue-rotate(360deg); }
}

/* 소파 기본 스타일 */
.sofa {
  width: 25vw;  /* 뷰포트 너비의 25% */
  height: auto; /* 비율 유지 */
  position: absolute;
  bottom: 0;     /* 바닥에 맞춤 */
  left: 0;       /* 왼쪽에 맞춤 */
  object-fit: contain; /* 이미지 비율 유지 */
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .roomWrapper {
    aspect-ratio: 4 / 3; /* 모바일에서는 더 정사각형에 가깝게 */
  }
  
  .window {
    width: 60%;
    height: 50%;
    left: 70%;
  }
  
  .furnitureItem.sofa {
    width: 60vw;
  }
  
  .bannerImage {
    width: 200px;
  }
  
  .businessSign {
    font-size: 18px;
    letter-spacing: 1px;
  }
  
  .ownerSign {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .roomWrapper {
    aspect-ratio: 1 / 1; /* 작은 화면에서는 정사각형 */
  }
  
  .window {
    width: 60%;
    height: 40%;
    left: 70%;
  }
  
  .furnitureItem.sofa {
    width: 70vw;
  }
  
  .bannerImage {
    width: 150px; /* 더 작은 화면에서 배너 크기 더 축소 */
  }
  
  .businessSign {
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  
  .cubeSigns {
    top: 10px;
  }
}

/* 다른 가구 아이템에 대한 반응형 스타일 추가 */
.chair {
  width: 15vw;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 30vw;
  object-fit: contain;
}

.table {
  width: 20vw;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 20vw;
  object-fit: contain;
}

@media (max-width: 768px) {
  .furnitureItem.chair {
    width: 20vw;
    left: 40vw;
  }
  
  .furnitureItem.table {
    width: 25vw;
    left: 15vw;
  }
}

@media (max-width: 480px) {
  .furnitureItem.chair {
    width: 25vw;
    left: 50vw;
  }
  
  .furnitureItem.table {
    width: 30vw;
    left: 10vw;
  }
}