.customerManagement {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: 'DungGeunMo', 'Press Start 2P', monospace;
}

.customerHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.customerHeader h2 {
  margin: 0;
  color: var(--secondary-color);
}

.searchBar {
  position: relative;
  width: 300px;
}

.searchBar input {
  width: 100%;
  padding: 10px 15px;
  padding-right: 40px;
  border: 3px solid var(--border-color);
  font-family: inherit;
  font-size: 14px;
  background-color: white;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.searchIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--secondary-color);
}

.customerContent {
  display: flex;
  gap: 20px;
  height: 100%;
}

.formContainer {
  flex: 0 0 320px;
  background-color: white;
  border: 2px solid var(--border-color);
  padding: 12px;
  height: 550px;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.formContainer h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--secondary-color);
  border-bottom: 4px solid var(--primary-color);
  padding-bottom: 10px;
}

.formSection {
  border: 2px solid var(--border-color);
  padding: 12px;
  margin-bottom: 12px;
  background-color: white;
  box-shadow: 3px 3px 0 var(--shadow-color);
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 8px 0;
}

.sectionHeader h4 {
  margin: 0;
  color: var(--secondary-color);
  font-size: 16px;
}

.toggleIcon {
  font-size: 12px;
  color: var(--secondary-color);
}

.sectionContent {
  padding-top: 10px;
}

.formSubsection {
  margin-bottom: 20px;
}

.formSubsection h5 {
  margin-top: 0;
  margin-bottom: 10px;
  color: var(--secondary-color);
  font-size: 14px;
}

.formRow {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;
}

.formGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.formGroup.fullWidth {
  flex: 0 0 100%;
}

.formGroup label {
  margin-bottom: 5px;
  color: var(--secondary-color);
  font-size: 14px;
}

.formGroup input, 
.formGroup select, 
.formGroup textarea {
  width: 100%;
  padding: 8px 10px;
  border: 2px solid var(--border-color);
  font-family: inherit;
  font-size: 13px;
  background-color: white;
  box-shadow: 3px 3px 0 var(--shadow-color);
  height: 36px;
  box-sizing: border-box;
  min-width: 0;
}

.formGroup input:focus,
.formGroup select:focus,
.formGroup textarea:focus {
  outline: none;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.formGroup textarea {
  height: auto;
  min-height: 60px;
}

.childrenList {
  margin-bottom: 15px;
  max-height: 150px;
  overflow-y: auto;
  border: 3px solid var(--border-color);
  background-color: white;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.childItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 3px solid var(--border-color);
}

.childItem:last-child {
  border-bottom: none;
}

.childInfo {
  display: flex;
  flex-direction: column;
}

.childName {
  font-weight: bold;
  font-size: 14px;
  color: var(--secondary-color);
}

.childDetail {
  font-size: 12px;
  color: var(--secondary-color);
}

.btnRemoveChild {
  padding: 3px 8px;
  background-color: var(--error-color);
  color: white;
  border: 2px solid var(--border-color);
  cursor: pointer;
  font-size: 12px;
  font-family: inherit;
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.btnAddChild {
  padding: 8px 15px;
  background-color: var(--success-color);
  color: white;
  border: 3px solid var(--border-color);
  cursor: pointer;
  font-size: 14px;
  font-family: inherit;
  box-shadow: 4px 4px 0 var(--shadow-color);
  margin-top: 24px;
}

.btnAddChild:hover,
.btnRemoveChild:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.btnAddChild:active,
.btnRemoveChild:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.childAddBtnContainer {
  display: flex;
  align-items: flex-end;
}

.customerList {
  flex: 1;
  overflow-y: auto;
  height: 550px;
}

.customerCard {
  background-color: white;
  border: 2px solid var(--border-color);
  margin-bottom: 12px;
  box-shadow: 3px 3px 0 var(--shadow-color);
  width: 100%;
  box-sizing: border-box;
}

.customerCard:hover {
  transform: translate(-1px, -1px);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.customerCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background-color: var(--background-color);
  border-bottom: 2px solid var(--border-color);
  width: 100%;
  box-sizing: border-box;
}

.customerInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.customerInfo h4 {
  margin: 0;
  color: var(--secondary-color);
  font-size: 15px;
  white-space: nowrap;
}

.categoryBadge {
  padding: 4px 8px;
  border: 2px solid var(--border-color);
  font-size: 12px;
  color: white;
  box-shadow: 2px 2px 0 var(--shadow-color);
  white-space: nowrap;
}

.customerActions {
  display: flex;
  gap: 5px;
  margin-left: auto;
}

.btnEdit, .btnDelete {
  padding: 5px 10px;
  border: 2px solid var(--border-color);
  font-family: inherit;
  font-size: 12px;
  box-shadow: 2px 2px 0 var(--shadow-color);
  cursor: pointer;
}

.btnEdit {
  background-color: var(--accent-color);
  color: white;
}

.btnDelete {
  background-color: var(--error-color);
  color: white;
}

.btnEdit:hover,
.btnDelete:hover {
  transform: translate(-2px, -2px);
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.btnEdit:active,
.btnDelete:active {
  transform: translate(2px, 2px);
  box-shadow: 1px 1px 0 var(--shadow-color);
}

.detailRow {
  display: flex;
  margin-bottom: 5px;
  font-size: 13px;
  width: 100%;
  box-sizing: border-box;
}

.detailLabel {
  flex: 0 0 90px;
  font-weight: bold;
  color: var(--secondary-color);
  white-space: nowrap;
}

.detailValue {
  flex: 1;
  color: var(--secondary-color);
  word-break: break-all;
}

.customerDetails {
  padding: 10px 12px;
  width: 100%;
  box-sizing: border-box;
}

.noCustomers {
  padding: 20px;
  text-align: center;
  color: var(--secondary-color);
  background-color: white;
  border: 3px solid var(--border-color);
  box-shadow: 4px 4px 0 var(--shadow-color);
  font-size: 13px;
}

.btnPrimary {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: 3px solid var(--border-color);
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  box-shadow: 4px 4px 0 var(--shadow-color);
  text-transform: uppercase;
}

.btnSecondary {
  padding: 10px 20px;
  background-color: var(--secondary-color);
  color: white;
  border: 3px solid var(--border-color);
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  box-shadow: 4px 4px 0 var(--shadow-color);
  text-transform: uppercase;
}

.btnPrimary:hover,
.btnSecondary:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 var(--shadow-color);
}

.btnPrimary:active,
.btnSecondary:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 var(--shadow-color);
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

/* 스크롤바 스타일 */
.formContainer::-webkit-scrollbar,
.customerList::-webkit-scrollbar {
  width: 16px;
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

.formContainer::-webkit-scrollbar-thumb,
.customerList::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 3px solid var(--border-color);
}

.formContainer::-webkit-scrollbar-track,
.customerList::-webkit-scrollbar-track {
  background: var(--background-color);
  border: 3px solid var(--border-color);
}

@media (max-width: 768px) {
  .customerContent {
    flex-direction: column;
  }
  
  .formContainer {
    flex: none;
    width: 100%;
  }
  
  .formRow {
    flex-direction: column;
  }
  
  .btnAddChild {
    margin-top: 0;
  }
  
  .childAddBtnContainer {
    align-items: center;
  }
  
  .searchBar {
    width: 100%;
    margin-top: 10px;
  }
  
  .customerHeader {
    flex-direction: column;
    align-items: stretch;
  }
}

.suggestionsTable {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border: 3px solid var(--border-color);
  box-shadow: 6px 6px 0 var(--shadow-color);
  table-layout: fixed;
}

.suggestionsTable th,
.suggestionsTable td {
  padding: 12px 15px;
  border: 2px solid var(--border-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.suggestionsTable th:nth-child(1),
.suggestionsTable td:nth-child(1) {
  width: 100px;
}

.suggestionsTable th:nth-child(2),
.suggestionsTable td:nth-child(2) {
  width: 100px;
}

.suggestionsTable th:nth-child(3),
.suggestionsTable td:nth-child(3) {
  width: auto;
}

.suggestionsTable th:nth-child(4),
.suggestionsTable td:nth-child(4) {
  width: 90px;
}

.suggestionsTable th:nth-child(5),
.suggestionsTable td:nth-child(5) {
  width: 120px;
}

.suggestionsTable th:nth-child(6),
.suggestionsTable td:nth-child(6) {
  width: 70px;
  text-align: center;
}

.suggestionContent {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} 