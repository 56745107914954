/* 사진첩 - 레트로 픽셀아트 스타일 */
.diaryPhotobook {
  --photo-primary-color: #000000;
  --photo-secondary-color: #d1d8e0;
  --photo-accent-color: #ff4757;
  --photo-background-color: #ffffff;
  --photo-border-color: #2f3542;
  --photo-shadow-color: rgba(0, 0, 0, 0.25);
  
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  image-rendering: pixelated;
}

.diaryPhotobookHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  border-bottom: 2px solid #000000;
  padding-bottom: 10px;
}

.diaryPhotobook h2 {
  font-size: 24px;
  color: white;
  text-align: center;
  font-weight: bold;
  margin: 0 20px;
  padding: 10px 15px;
  position: relative;
  background-color: var(--photo-accent-color);
  border: 2px solid #000000;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.diaryPhotobook h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 2px;
  background: linear-gradient(to right, transparent, #ff9ebe, transparent);
}

.diarySticker {
  width: 20px;
  height: 20px;
  background-color: var(--photo-accent-color);
  border: 1px solid #000000;
}

.stickerHeart {
  background-image: none;
}

.stickerStar {
  background-image: none;
}

.diaryPhotobookGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 18px;
  padding: 15px;
  overflow-y: auto;
  flex: 1;
  border: 2px solid #000000;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.diaryPhotobookGrid::-webkit-scrollbar {
  width: 10px;
  background-color: #e6e6e6;
  border-left: 2px solid #000000;
}

.diaryPhotobookGrid::-webkit-scrollbar-thumb {
  background-color: #454545;
  border: 2px solid #e6e6e6;
}

.diaryPhotobookGrid::-webkit-scrollbar-button {
  background-color: #e6e6e6;
  height: 10px;
  border: 1px solid #000000;
}

.diaryPhotobookGrid::-webkit-scrollbar-button:vertical:start {
  background-image: linear-gradient(to top, #000000 40%, transparent 40%);
  background-size: 6px 6px;
  background-repeat: no-repeat;
  background-position: center;
}

.diaryPhotobookGrid::-webkit-scrollbar-button:vertical:end {
  background-image: linear-gradient(to bottom, #000000 40%, transparent 40%);
  background-size: 6px 6px;
  background-repeat: no-repeat;
  background-position: center;
}

.diaryPhotoItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: all 0.2s ease;
  transform: none;
}

.diaryPhotoItem:nth-child(2n) {
  transform: none;
}

.diaryPhotoItem:hover {
  transform: translate(-2px, -2px);
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.diaryPhotoFrame {
  border: 4px solid #000000;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
  background-color: white;
  position: relative;
  overflow: hidden;
}

.diaryPhotoPlaceholder {
  width: 100%;
  aspect-ratio: 1 / 1;
  min-width: 100px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 12px;
  background-image: none;
}

.diaryPhotoTape {
  width: 30px;
  height: 10px;
  background-color: var(--photo-accent-color);
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #000000;
}

.diaryPhotoCaption {
  margin-top: 10px;
  font-size: 12px;
  color: #000000;
  text-align: center;
  font-weight: bold;
  max-width: 140px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 0;
  border: 2px solid #000000;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
  transform: none;
}

.diaryPhotobookPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 20px 0 10px;
  padding: 5px 0;
}

.diaryPaginationButton {
  background-color: white;
  border: 2px solid #000000;
  padding: 6px 15px;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #000000;
  font-weight: bold;
  font-size: 12px;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  gap: 5px;
}

.diaryPaginationButton:hover {
  background-color: var(--photo-accent-color);
  color: white;
  transform: translate(-2px, -2px);
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.3);
}

.diaryPaginationButton:active {
  transform: translate(2px, 2px);
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.paginationNumber {
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  background-color: white;
  padding: 5px 15px;
  border-radius: 0;
  border: 2px solid #000000;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .diaryPhotobookGrid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
  
  .diaryPhotobook h2 {
    font-size: 20px;
    padding: 8px 12px;
  }
  
  .diaryPaginationButton,
  .paginationNumber {
    font-size: 11px;
    padding: 5px 10px;
  }
} 