.signupForm {

    display: flex;
  
    flex-direction: column;
    align-items: center;
  
    max-width: 400px;
  
    margin: 0 auto;
  
    padding: 20px;
  
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  
  }
  
  .formGroup {
    margin-bottom: 15px;
    width: 100%;
  
  }
  
  .formGroup label {
    display: block;
  
    margin-bottom: 5px;
    font-weight: bold;
  
  }
  
  
  .formInput {
    width: 90%;
    padding: 10px;
  
    border: 1px solid #ddd;
  
    border-radius: 5px;
    font-size: 16px;
  
  }
  
  .signupButton {
  
    padding: 10px 20px;
    border: none;
  
    border-radius: 5px;
  
    background-color: #20B2AA;
  
    color: white;
    font-size: 18px;
  
    font-weight: bold;
    cursor: pointer;
  
    transition: background-color 0.3s ease;
  }
  
  .signupButton:hover {
    background-color: #1E90FF;
  
  }
  