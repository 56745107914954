/* 직원 목록 섹션 */
.employeeListSection {
  width: 320px;
  flex-shrink: 0;
  border: 3px solid var(--border-color);
  padding: 10px;
  box-shadow: 4px 4px 0 var(--shadow-color);
  background-color: white;
  height: 600px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.employeeListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid var(--primary-color);
}

.employeeListHeader h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.addEmployeeBtn {
  padding: 6px 10px;
  background-color: var(--primary-color);
  color: white;
  border: 2px solid var(--border-color);
  box-shadow: 3px 3px 0 var(--shadow-color);
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
}

.addEmployeeBtn:hover {
  transform: translate(-2px, -2px);
  box-shadow: 5px 5px 0 var(--shadow-color);
}

.addEmployeeBtn:active {
  transform: translate(1px, 1px);
  box-shadow: 1px 1px 0 var(--shadow-color);
}

.employeeList {
  flex: 1;
  overflow-y: auto;
  border: 2px solid var(--border-color);
  padding: 5px;
  background-color: white;
}

/* 스크롤바 스타일 */
.employeeList::-webkit-scrollbar {
  width: 12px;
}

.employeeList::-webkit-scrollbar-track {
  background: var(--background-color);
}

.employeeList::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 6px;
  border: 3px solid var(--background-color);
}

.employeeItem {
  padding: 10px;
  cursor: pointer;
  border-bottom: 2px solid var(--border-color);
  position: relative;
  transition: all 0.2s ease;
}

.employeeItem::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: var(--primary-color);
  transition: all 0.2s ease;
  opacity: 0;
}

.employeeItem:hover {
  background: var(--background-color);
  transform: translate(-2px, 0);
}

.employeeItem:hover::before {
  opacity: 1;
  width: 6px;
}

.employeeItem.selected {
  background: var(--background-color);
  transform: translate(-2px, 0);
  font-weight: bold;
}

.employeeItem.selected::before {
  opacity: 1;
  width: 6px;
}

.employeeInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.employeeName {
  font-weight: bold;
}

.employeePosition {
  font-size: 12px;
  color: var(--secondary-color);
}

.noEmployees {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--secondary-color);
  font-size: 14px;
  text-align: center;
  padding: 20px;
  background-color: white;
  border: 2px solid var(--border-color);
  box-shadow: 3px 3px 0 var(--shadow-color);
  margin: 20px 0;
}

.searchBar {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

.searchBar input {
  width: 100%;
  padding: 8px 15px;
  padding-right: 40px;
  border: 3px solid var(--border-color);
  font-family: inherit;
  font-size: 14px;
  background-color: white;
  box-shadow: 4px 4px 0 var(--shadow-color);
}

.searchIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--secondary-color);
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid var(--background-color);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .employeeListSection {
    width: 100%;
    height: auto;
    max-height: 300px;
  }
  
  .employeeList {
    max-height: 200px;
  }
}

.employeeListTitle {
  margin: 10px 0;
  font-size: 14px;
  color: var(--secondary-color);
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 5px;
} 