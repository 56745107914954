/* 다이어리 모달 - 레트로 픽셀아트 스타일 */
.diaryModalOverlay {
  --modal-primary-color: #000000;
  --modal-secondary-color: #d1d8e0;
  --modal-accent-color: #ff4757;
  --modal-background-color: #ffffff;
  --modal-border-color: #2f3542;
  --modal-shadow-color: rgba(0, 0, 0, 0.25);
  
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.diaryModalContent {
  background-color: white;
  border-radius: 0;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3);
  border: 2px solid #000000;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  image-rendering: pixelated;
}

.diaryModalContent::-webkit-scrollbar {
  width: 10px;
  background-color: #e6e6e6;
  border-left: 2px solid #000000;
}

.diaryModalContent::-webkit-scrollbar-thumb {
  background-color: #454545;
  border: 2px solid #e6e6e6;
}

.diaryModalContent::-webkit-scrollbar-button {
  background-color: #e6e6e6;
  height: 10px;
  border: 1px solid #000000;
}

.diaryModalContent::-webkit-scrollbar-button:vertical:start {
  background-image: linear-gradient(to top, #000000 40%, transparent 40%);
  background-size: 6px 6px;
  background-repeat: no-repeat;
  background-position: center;
}

.diaryModalContent::-webkit-scrollbar-button:vertical:end {
  background-image: linear-gradient(to bottom, #000000 40%, transparent 40%);
  background-size: 6px 6px;
  background-repeat: no-repeat;
  background-position: center;
}

.diaryModalContent::before {
  content: '';
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px dashed #000000;
  z-index: 0;
}

.diaryModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #000000;
  position: relative;
}

.diaryModalHeader h3 {
  font-size: 18px;
  color: white;
  margin: 0;
  text-shadow: none;
  font-weight: bold;
  background-color: var(--modal-accent-color);
  padding: 8px 15px;
  border: 2px solid #000000;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.closeButton {
  background: white;
  border: 2px solid #000000;
  font-size: 20px;
  color: #000000;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.closeButton:hover {
  background-color: var(--modal-accent-color);
  color: white;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3);
}

.closeButton:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
}

/* 다이어리 탭 스타일 */
.diaryTabs {
  display: flex;
  overflow-x: auto;
  border-bottom: 2px solid #000000;
  margin-bottom: 15px;
  padding-bottom: 2px;
  position: relative;
}

.diaryTabs::-webkit-scrollbar {
  height: 8px;
  background-color: #e6e6e6;
}

.diaryTabs::-webkit-scrollbar-thumb {
  background-color: #454545;
  border: 1px solid #000000;
}

.diaryTabs::-webkit-scrollbar-button {
  display: none;
}

.diaryTab {
  padding: 8px 15px;
  background-color: white;
  border: 2px solid #000000;
  border-bottom: none;
  margin-right: 5px;
  cursor: pointer;
  font-size: 12px;
  color: #000000;
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  transition: all 0.2s ease;
  font-weight: bold;
  box-shadow: 2px 0 0 rgba(0, 0, 0, 0.2);
}

.diaryTab.active {
  background-color: var(--modal-accent-color);
  color: white;
  position: relative;
  top: 2px;
  z-index: 2;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
}

.diaryTab.active::before, 
.diaryTab.active::after {
  display: none;
}

.diaryTab span {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabCloseBtn {
  background: none;
  border: 1px solid #000000;
  color: #000000;
  margin-left: 8px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 0;
  transition: all 0.2s ease;
}

.tabCloseBtn:hover {
  background-color: var(--modal-accent-color);
  color: white;
  transform: translate(-1px, -1px);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
}

.diaryAddTab {
  padding: 8px 12px;
  background-color: white;
  border: 2px solid #000000;
  border-bottom: none;
  cursor: pointer;
  color: #000000;
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  box-shadow: 2px 0 0 rgba(0, 0, 0, 0.2);
}

.diaryAddTab:hover {
  background-color: var(--modal-accent-color);
  color: white;
  transform: translate(-2px, -2px);
  box-shadow: 4px 2px 0 rgba(0, 0, 0, 0.3);
}

/* 폼 스타일 */
.diaryFormGroup {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.diaryFormRow {
  display: flex;
  gap: 15px;
  position: relative;
}

.diaryFormRow.fullWidth {
  display: block;
}

.diaryFormCol {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.diaryFormCol label,
.diaryFormRow label {
  font-weight: bold;
  margin-bottom: 8px;
  color: #000000;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.diaryFormCol label::before,
.diaryFormRow label::before {
  content: '▶';
  margin-right: 5px;
  font-size: 10px;
  color: var(--modal-accent-color);
}

.diaryFormCol input,
.diaryFormCol textarea,
.diaryFormRow textarea {
  padding: 10px;
  border: 2px solid #000000;
  border-radius: 0;
  background-color: white;
  font-size: 13px;
  resize: vertical;
  min-height: 36px;
  transition: all 0.2s ease;
  color: #000000;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.2);
}

.diaryFormCol input:focus,
.diaryFormCol textarea:focus,
.diaryFormRow textarea:focus {
  outline: none;
  border-color: var(--modal-accent-color);
  box-shadow: 3px 3px 0 rgba(255, 71, 87, 0.3);
}

.diaryFormCol textarea {
  min-height: 80px;
}

.diaryMemoTextarea {
  min-height: 120px;
  background-color: #ffffff;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
}

.diaryModalActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.diaryModalActions button {
  padding: 8px 15px;
  font-weight: bold;
  border: 2px solid #000000;
  border-radius: 0;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.2s ease;
  font-family: 'DungGeunMo', 'Press Start 2P', 'Noto Sans KR', sans-serif;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
}

.saveButton {
  background-color: var(--modal-accent-color);
  color: white;
}

.saveButton:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3);
}

.saveButton:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
}

.cancelButton {
  background-color: white;
  color: #000000;
}

.cancelButton:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3);
}

.cancelButton:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .diaryFormRow {
    flex-direction: column;
    gap: 10px;
  }
  
  .diaryModalContent {
    width: 95%;
    padding: 15px;
  }
  
  .diaryModalHeader h3 {
    font-size: 16px;
    padding: 6px 12px;
  }
  
  .diaryModalActions button {
    padding: 6px 12px;
    font-size: 12px;
  }
} 